import { UPDATE_LOCATION } from 'react-router-redux';
import * as types from '../constants/actionTypes-constants';
import _get from 'lodash/get';
import _without from 'lodash/without';
import { STATUS_APPROVED } from '../constants/backend-constants';
import { safe } from '../utils/utils';

const defaultState = {
  vehicleCompanyName: '',
  paginatedResults: null,
  openedUpdatePriceModal: false,
  openedCancelModalBooking: null,
  urlParams: null,
  currentSortedIndex: null,
  sortIsDescending: false,
  facets: null,
  bookingFiltersFormIsOpen: false,
  bookingDetail: null,
  bookingDelayed: false,
  bookingTransactions: [],
  bookingInvoices: [],
  bookingExternalErrors: [],
  bookingEvents: [],
  bookingFiles: [],
  startError: {},
  openedStartModalBooking: null,
  openedFinishModalBooking: null,
  openedInvoiceCreationModal: null,
  openedEventsHistoryBookingModal: null,
  openedExtendShortenModalBooking: null,
  openedChangeVehicleModalBooking: null,
  openedFixIntegrationErrorsModalBooking: null,
  exporting: null,
  loadingList: true,
  errorList: false,
  filters: undefined
};

export default function bookings(state = defaultState, action) {
  switch (action.type) {
    case types.BOOKINGS_CANCEL_BOOKING_REQUEST:
    case types.BOOKINGS_GET_DETAILS_REQUEST:
    case types.BOOKINGS_EXTEND_SHORTEN_BOOKING_SUCCESS:
    case types.BOOKINGS_FINISH_BOOKING_REQUEST:
    case types.BOOKINGS_FINISH_BOOKING_SUCCESS:
      return state;
    case types.BOOKING_START_REQUEST:
      return { ...state, startError: {} };

    case types.BOOKINGS_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false
      };

    case types.BOOKING_AUTOMATIC_PAYMENT_CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          carSharingInfo: { ...state.bookingDetail.carSharingInfo, automaticPaymentRetry: action.newStatus }
        }
      };
    }

    case types.BOOKINGS_UPDATE_FILTERS: {
      return { ...state, filters: action.filters };
    }

    case types.BOOKINGS_GET_LIST_SUCCESS:
      return {
        ...state,
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      };

    case types.BOOKINGS_LIST_SET_ITEM_STATUS: {
      const results = safe(() => state.paginatedResults.results);
      if (!results) return state;

      return {
        ...state,
        paginatedResults: {
          ...state.paginatedResults,
          results: results.map(item => {
            if (item.id === action.id) {
              item.status = action.status;
            }
            return item;
          })
        }
      };
    }

    case types.BOOKINGS_DETAIL_UPDATE_VEHICLE: {
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          vehicle: action.data.vehicle,
          start: action.data.start,
          end: action.data.end
        }
      };
    }

    case types.BOOKINGS_LIST_UPDATE_VEHICLE: {
      const results = safe(() => state.paginatedResults.results);
      if (!results) return state;
      return {
        ...state,
        paginatedResults: {
          ...state.paginatedResults,
          results: results.map(item => {
            if (item.id === action.id) {
              item.vehicleId = action.vehicle.id;
              item.vehicleRegistrationNumber = safe(() => action.vehicle.registrationNumber);
              item.vehicleBrandName = safe(() => action.vehicle.version.model.brand.name);
              item.vehicleModelName = safe(() => action.vehicle.version.model.name);
            }
            return item;
          })
        }
      };
    }

    case types.BOOKINGS_GET_VEHICLE_COMPANY_SUCCESS:
      return {
        ...state,
        vehicleCompanyName: _get(action, 'companyDetail.name')
      };

    case types.BOOKINGS_GET_VEHICLE_COMPANY_REQUEST:
    case types.BOOKINGS_GET_VEHICLE_COMPANY_ERROR: {
      return {
        ...state,
        vehicleCompanyName: ''
      };
    }

    case types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_REQUEST:
    case types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_ERROR:
      return {
        ...state,
        bookingTransactions: []
      };

    case types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingTransactions: _get(action, 'data')
      };
    }

    case types.BOOKINGS_GET_INVOICES_FOR_BOOKING_REQUEST:
    case types.BOOKINGS_GET_INVOICES_FOR_BOOKING_ERROR:
      return {
        ...state,
        bookingInvoices: []
      };

    case types.BOOKINGS_GET_INVOICES_FOR_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingInvoices: _get(action, 'data')
      };
    }
    case types.BOOKING_START_SUCCESS: {
      return {
        ...state,
        bookingInvoices: _get(action, 'data'),
        startError: {}
      };
    }
    case types.BOOKING_START_ERROR: {
      return {
        ...state,
        openedStartModalBooking: true,
        startError: action
      };
    }
    case types.BOOKINGS_GET_LIST_ERROR: {
      return {
        ...state,
        errorList: true
      };
    }

    case types.BOOKINGS_SET_CURRENT_DELAYED_STATUS: {
      return { ...state, bookingDelayed: action.delayed };
    }

    case types.BOOKINGS_GET_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        bookingDetail: action.booking
      });
    }

    case types.BOOKING_START_MODAL_OPEN:
      return Object.assign({}, state, {
        openedStartModalBooking: true
      });

    case types.BOOKING_START_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedStartModalBooking: null
      });

    case types.BOOKINGS_CANCEL_BOOKING_MODAL_OPEN:
      return Object.assign({}, state, {
        openedCancelModalBooking: true
      });

    case types.BOOKINGS_CANCEL_BOOKING_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedCancelModalBooking: null
      });

    case types.BOOKING_SETTLE_SUCCESS:
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          paymentResettlingAllowed: false
        }
      };

    case types.BOOKINGS_SET_CURRENT_SORTED_INDEX:
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });

    case types.BOOKINGS_SORT_DIRECTION_SET:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case types.BOOKINGS_FACETS_SET:
      return Object.assign({}, state, {
        facets: action.facets
      });

    case types.BOOKINGS_FILTERS_FORM_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        bookingFiltersFormIsOpen: !state.bookingFiltersFormIsOpen
      });

    case types.BOOKINGS_FINISH_BOOKING_MODAL_OPEN:
      return Object.assign({}, state, {
        openedFinishModalBooking: true
      });

    case types.BOOKINGS_FINISH_BOOKING_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedFinishModalBooking: null
      });

    case types.BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_OPEN:
      return Object.assign({}, state, {
        openedExtendShortenModalBooking: true
      });

    case types.BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedExtendShortenModalBooking: null
      });

    case types.BOOKING_FIX_ERRORS_PROPLANNER_MODAL_OPEN:
      return Object.assign({}, state, {
        openedFixIntegrationErrorsModalBooking: true
      });

    case types.BOOKING_FIX_ERRORS_PROPLANNER_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedFixIntegrationErrorsModalBooking: null
      });

    case types.BOOKINGS_EXPORT_REQUEST:
      return Object.assign({}, state, {
        exporting: true
      });

    case types.BOOKINGS_EXPORTV3_REQUEST:
      return Object.assign({}, state, {
        exportingV3: true
      });

    case types.BOOKINGS_EXPORT_FINISHED:
      return Object.assign({}, state, {
        exporting: null
      });

    case types.BOOKINGS_EXPORTV3_FINISHED:
      return Object.assign({}, state, {
        exportingV3: null
      });

    case types.BOOKING_UPDATE_PRICE_MODAL_TOGGLE:
      return {
        ...state,
        openedUpdatePriceModal: !state.openedUpdatePriceModal
      };

    case types.BOOKING_UPDATE_CHANGE_VEHICLE:
      return {
        ...state,
        openedChangeVehicleModalBooking: !state.openedChangeVehicleModalBooking
      };

    case types.BOOKINGS_CREATE_INVOICE_BOOKING_MODAL_TOGGLE:
      return {
        ...state,
        openedInvoiceCreationModal: !state.openedInvoiceCreationModal
      };

    case types.BOOKING_DRIVING_LICENSE_DETAILS_SUCCESS:
      let files = state.bookingFiles;
      files.push(action.file);
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          drivingLicenceMetaData: {
            files,
            status: STATUS_APPROVED,
            ...state.bookingDetail.drivingLicenceMetaData
          }
        },
        bookingFiles: files
      };

    case types.BOOKING_DRIVING_LICENSE_FILES_EMPTY:
      return {
        ...state,
        bookingFiles: []
      };

    case types.BOOKING_DRIVING_LICENSE_DETAILS_ERROR:
      let driverFiles = _without(_get(state, 'bookingFiles', []), action.fileToRemove);

      return {
        ...state,
        bookingDetail: {
          ..._get(state, 'bookingDetail', {}),
          drivingLicenceMetaData: {
            ..._get(state, 'bookingDetail.drivingLicenceMetaData', {}),
            files: driverFiles
          }
        }
      };

    case types.BOOKING_EVENTS_HISTORY_REQUEST:
    case types.BOOKING_EVENTS_HISTORY_ERROR:
      return {
        ...state,
        bookingEvents: []
      };

    case types.BOOKING_EVENTS_HISTORY_SUCCESS:
      return {
        ...state,
        bookingEvents: action.bookingEvents
      };
    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false
      });

    case types.BOOKING_CLEAR_LIST_DATA:
      return {
        ...state,
        paginatedResults: []
      };

    case types.BOOKING_CLEAR_ALL_DATA:
      return defaultState;

    case types.BOOKINGS_MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          member: action.member
        }
      };
    case types.BOOKING_GET_ERRORS_PROPLANNER_SUCCESS:
      return {
        ...state,
        bookingExternalErrors: action.data
      };
    case types.BOOKING_GET_ERRORS_PROPLANNER_CLEAR:
      return {
        ...state,
        bookingExternalErrors: []
      };

    default:
      return state;
  }
}
