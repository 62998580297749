import React, { Component } from 'react';
import autoBind from 'react-autobind';
import {
  iconStyles,
  DetailViewStyles,
  modalStyles,
  cssColors,
  extendShortenModalStyles,
  updateBookingPriceFormStyle,
  AddInvoiceModalStyles,
  startBookingModalStyles
} from '../../constants/style-constants';
import BackLink from '../../components/BackLink/BackLink';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  namedCompose,
  newProps,
  addInvoiceItemsTotal,
  safe,
  getBookingInfo,
  createInitVehiclePlanning,
  isValidId,
  toBoolean,
  addErrorMessage
} from '../../utils/utils';
import IconList from '../../components/IconList/IconList';
import {
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ADMIN,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_TYPE_CAR_SHARING,
  BOOKING_USAGE_TYPE_PRIVATE,
  STATUS_CANCELED,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BOOKING_STATUS_PRE_BOOKED,
  EXTERNAL_SYSTEM_LINKS
} from '../../constants/backend-constants';
import _get from 'lodash/get';
import _has from 'lodash/has';

import BookingInfo from '../../components/BookingInfo/BookingInfo';
import BookingMoreInfo from '../../components/BookingMoreInfo/BookingMoreInfo';
import BookingBilling from '../../components/BookingBilling/BookingBilling';
import BookingTripInfo from '../../components/BookingTripInfo/BookingTripInfo';
import TripMoreInfo from '../../components/TripMoreInfo/TripMoreInfo';
import CreateInvoiceForm from '../../components/CreateInvoiceForm/CreateInvoiceForm';
import BookingVehicleInfo from '../../components/BookingVehicleInfo/BookingVehicleInfo';
import BookingTransactionInfo from '../../components/BookingTransactionInfo/BookingTransactionInfo';
import Tooltip from '../../components/Tooltip/Tooltip';
import BookingStatusHistory from '../../components/BookingStatusHistory/BookingStatusHistory';
import MemberDriverInfo from '../../components/MemberDriverInfo/MemberDriverInfo';
import { bundleSelector, localeSelector } from '../../selectors/all-selectors';

import CancelIcon from 'material-ui/svg-icons/content/block';
import EditIcon from 'material-ui/svg-icons/image/edit';
import ChangeIcon from 'material-ui/svg-icons/action/swap-horiz';
import PriceIcon from 'material-ui/svg-icons/editor/attach-money';
import PaymentResettleIcon from 'material-ui/svg-icons/navigation/refresh';
import RetryBookingIcon from 'material-ui/svg-icons/editor/publish';
import PeopleIcon from 'material-ui/svg-icons/social/people';
import Person from 'material-ui/svg-icons/social/person';
import PlayArrow from 'material-ui/svg-icons/av/play-arrow';
import RefreshIcon from 'material-ui/svg-icons/action/autorenew';
import DirectionsCarIcon from 'material-ui/svg-icons/maps/directions-car';
import Add from 'material-ui/svg-icons/content/add';
import History from 'material-ui/svg-icons/action/history';
import Close from 'material-ui/svg-icons/navigation/close';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import AutomaticPaymentIcon from 'material-ui/svg-icons/action/credit-card';

import {
  bookingViewGetInvoices,
  closeCancelBookingModal,
  closeExtendShortenBookingModal,
  closeFinishBookingModal,
  getBookingTransactions,
  getSingleBooking,
  openCancelBookingModal,
  openExtendShortenBookingModal,
  openFinishBookingModal,
  requestBookingCancel,
  requestBookingExtendShorten,
  requestBookingFinish,
  retryRrsBooking,
  retryUnitRequest,
  settleBooking,
  toggleCreateInvoiceBookingModal,
  emptyDriverLicenseFiles,
  changeAutomaticPaymentStatus,
  retryDmsPayment,
  retryItalianInvoice,
  openStartBookingModal,
  closeStartBookingModal,
  startBooking,
  fixProplannerError,
  openFixIntegrationErrorsModal,
  closeFixIntegrationErrorsModal
} from '../../actions/bookings-actions';
import {
  getBookingVehiclePlanning,
  saveUserTypeForBookingEdit,
  toggleVehiclePlanningEditBooking,
  toggleShortenExtendBooking,
  toggleChangeMember,
  toggleChangeVehicle,
  getDataVehiclePlanning
} from '../VehiclePlanning/VehiclePlanning.actions';
import {
  addInvoiceItem,
  clearItalianInvoiceStatus,
  createInvoice,
  deleteInvoiceItem,
  toggleDepositUsage
} from '../../actions/invoices-actions';
import { addFlashMessage } from '../../actions/flashMessage-actions';
import { ALL, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import Modal from 'react-modal';
import EkButton from '../../components/EkButton/EkButton';
import routes, { bookingEditRules, invoiceEditRules, invoiceRules } from '../../constants/routes-constants';
import { routeActions } from 'react-router-redux';
import ReactSVG from 'react-svg';
import { apiParams } from '../../constants/api-params-constants';
import moment from 'moment';
import ExtendShortenBookingForm from '../../components/ExtendShortenBookingForm/ExtendShortenBookingForm';
import FinalizeBookingForm from '../../components/FinalizeBookingForm/FinalizeBookingForm';
import UpdateBookingPriceForm from '../../components/UpdateBookingPriceForm/UpdateBookingPriceForm';
import BookingFixIntegrationErrorsForm from '../../components/BookingFixIntegrationErrorsForm/BookingFixIntegrationErrorsForm';
import { bookingUpdatePrice, bookingUpdatePriceCheck, toggleUpdatePriceModal } from '../../actions/booking-actions';
import { getImpersonateMember } from '../../actions/members-actions';
import { refreshPage } from '../../routing/helpers-routing';
import merge from 'merge-deep';
import Checkbox from 'material-ui/Checkbox';
import { IconButton } from 'material-ui';
import classnames from 'classnames';
import VehiclePlanningMemberChange from '../_v2/VehiclePlanning/components/VehiclePlanningMemberChange';
import { enhanceSearchVehicleData } from '../../api/data-enhancer';
import { getBookingFindAvailableVehicle } from '../../actions/booking-find-vehicle-actions';
import VehiclePlanningChangeVehicle from '../_v2/VehiclePlanning/components/VehiclePlanningChangeVehicle';
import BookingStartForm from '../../components/BookingStartForm/BookingStartForm';
import { BOOKING_START_ERROR } from '../../constants/actionTypes-constants';

class BookingView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setVariables();
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    this.mounted = false;
    dispatch(emptyDriverLicenseFiles());
    dispatch(clearItalianInvoiceStatus());
  }

  derivedStateFromProps(props, init) {
    this.parseNames(props, init);
    this.updateIconList(props, init);
  }

  setVariables() {
    this.mounted = true;
    this.invoiceRules = merge(invoiceRules, invoiceEditRules);
  }

  parseNames(props, init) {
    if (!newProps(this.props, props, init, ['bookingStatus', 'bookingType', 'bookingUsageType', 'urlParams', 'locale'])) return false;

    const { bookingStatus, bookingType, bookingUsageType, urlParams } = props;
    const { formatMessage } = props.intl;

    const encodedParams = encodeURIComponent(JSON.stringify(urlParams || apiParams.default));

    this.backToListURL = `/#${routes.bookings.path.replace(':search', encodedParams)}`;
    this.bookingStatusClass = 'booking_status_' + String(bookingStatus).toLowerCase();
    this.bookingStatus = formatMessage({
      id: 'common_booking_status_' + bookingStatus,
      defaultMessage: formatMessage({ id: 'common_unknown' })
    });
    this.bookingType = formatMessage({
      id:
        bookingType === BOOKING_TYPE_CAR_SHARING
          ? `booking_detail_type_${bookingType}_${bookingUsageType}`
          : `booking_detail_type_${bookingType}`,
      defaultMessage: formatMessage({ id: 'common_unknown' })
    });
  }

  handleChangeAutomaticPaymentRetry() {
    const { automaticPaymentRetry, dispatch, bookingId } = this.props;
    dispatch(changeAutomaticPaymentStatus(bookingId, !automaticPaymentRetry));
  }

  handleChangeDmsPaymentRetry() {
    const { dispatch, bookingId } = this.props;
    dispatch(retryDmsPayment(bookingId));
  }

  handleItalianInvoiceRetry() {
    const { italianInvoiceError, dispatch } = this.props;
    const italianInvoiceNumber = italianInvoiceError.italianInvoiceNumber;
    dispatch(retryItalianInvoice({ italianInvoiceNumber }));
  }

  updateIconList(props, init) {
    if (
      !newProps(this.props, props, init, [
        'bookingStatus',
        'paymentResettlingAllowed',
        'bookingUsageType',
        'freeOfCharges',
        'rrsFailed',
        'integrationFailed',
        'carSharingFailed',
        'locale',
        'automaticPaymentRetry',
        'italianInvoiceError',
        'dmsInvoiceErrors'
      ])
    )
      return false;

    const {
      bookingStatus,
      paymentResettlingAllowed,
      bookingUsageType,
      freeOfCharges,
      rrsFailed,
      integrationFailed,
      carSharingFailed,
      automaticPaymentRetry,
      italianInvoiceError,
      dmsInvoiceErrors,
      handleToggle,
      booking
    } = props;

    this.iconListItems = [];

    if (!handleToggle)
      this.iconListItems.push({
        id: 'refresh_button',
        labelKey: 'common_refresh',
        icon: <RefreshIcon color={cssColors.listItem} />,
        handleClick: refreshPage
      });

    if (
      bookingStatus === BOOKING_STATUS_IN_PROGRESS ||
      bookingStatus === BOOKING_STATUS_SCHEDULED ||
      bookingStatus === BOOKING_STATUS_PRE_BOOKED
    )
      this.iconListItems.push({
        id: 'cancel_button',
        labelKey: 'booking_detail_cancel_booking',
        icon: <CancelIcon color={cssColors.listItem} />,
        handleClick: this.handleCancelBooking,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });

    if (bookingStatus === BOOKING_STATUS_SCHEDULED || bookingStatus === BOOKING_STATUS_PRE_BOOKED)
      this.iconListItems.push({
        id: 'edit_button',
        labelKey: 'booking_detail_edit_booking',
        icon: <EditIcon color={cssColors.listItem} />,
        handleClick: this.handleEditBooking,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });

    if (bookingStatus === BOOKING_STATUS_SCHEDULED || bookingStatus === BOOKING_STATUS_PRE_BOOKED)
      this.iconListItems.push({
        id: 'change_vehicle',
        labelKey: 'vehiclePlanning_changeVehicle',
        icon: <DirectionsCarIcon color={cssColors.listItem} />,
        handleClick: this.handleVehicleChange,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });

    if (bookingStatus === BOOKING_STATUS_SCHEDULED)
      this.iconListItems.push({
        id: 'change_mebmer',
        labelKey: 'vehiclePlanning_changeMember',
        icon: <Person color={cssColors.listItem} />,
        handleClick: this.handleMemberChange,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });

    if (bookingStatus === BOOKING_STATUS_SCHEDULED)
      this.iconListItems.push({
        id: 'start-booking',
        labelKey: 'booking_detail_start_booking',
        icon: <PlayArrow color={cssColors.listItem} />,
        handleClick: this.handleBookingStart,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });

    if (bookingStatus === BOOKING_STATUS_IN_PROGRESS)
      this.iconListItems.push({
        id: 'extend_button',
        labelKey: 'booking_detail_extend_shorten_booking',
        icon: <ChangeIcon color={cssColors.listItem} />,
        handleClick: this.handleExtendShortenBooking,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });
    if (bookingStatus === BOOKING_STATUS_IN_PROGRESS)
      this.iconListItems.push({
        id: 'finish_button',
        labelKey: 'booking_detail_finish_booking',
        icon: <ReactSVG src="/img/flag.svg" className="list-item-icon" svgStyle={iconStyles.listItem} />,
        handleClick: this.handleFinishBooking,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });
    if (
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE &&
      !freeOfCharges &&
      (bookingStatus === STATUS_CANCELED || bookingStatus === BOOKING_STATUS_COMPLETED)
    )
      this.iconListItems.push({
        id: 'update_price_button',
        labelKey: 'common_update_price',
        icon: <PriceIcon color={cssColors.listItem} />,
        handleClick: this.handleToggleUpdatePriceModal,
        allowed: [
          BACKUSER_ROLE_ROOT,
          BACKUSER_ROLE_SUPER_ADMIN,
          BACKUSER_ROLE_ADMIN,
          BACKUSER_ROLE_ZONE_MANAGER,
          BACKUSER_ROLE_ADMIN_DEALER
        ]
      });
    if (paymentResettlingAllowed)
      this.iconListItems.push({
        id: 'payment_resettle_button',
        labelKey: 'booking_detail_settle_booking',
        icon: <PaymentResettleIcon color={cssColors.listItem} />,
        handleClick: this.handleSettleBooking,
        allowed: [
          BACKUSER_ROLE_ROOT,
          BACKUSER_ROLE_SUPER_ADMIN,
          BACKUSER_ROLE_ADMIN,
          BACKUSER_ROLE_ZONE_MANAGER,
          BACKUSER_ROLE_ADMIN_DEALER
        ]
      });

    if (bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE && bookingStatus === BOOKING_STATUS_COMPLETED) {
      this.iconListItems.push({
        id: 'payment_automatic_retry_button',
        labelKey: automaticPaymentRetry ? 'booking.disable.automatic.retry.payment' : 'booking.enable.automatic.retry.payment',
        icon: <AutomaticPaymentIcon color={automaticPaymentRetry ? cssColors.listItem : cssColors.detailInfo} />,
        handleClick: this.handleChangeAutomaticPaymentRetry,
        allowed: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_FLEET_MANAGER]
      });
    }

    if (
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE &&
      (bookingStatus === BOOKING_STATUS_COMPLETED || bookingStatus === STATUS_CANCELED) &&
      safe(() => dmsInvoiceErrors.length)
    )
      this.iconListItems.push({
        id: 'payment_retry_dms_utton',
        labelKey: 'booking.enable.dms.retry.payment',
        icon: <PriceIcon color={cssColors.listItem} />,
        handleClick: this.handleChangeDmsPaymentRetry
      });

    if (
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE &&
      (bookingStatus === BOOKING_STATUS_COMPLETED || bookingStatus === STATUS_CANCELED) &&
      italianInvoiceError
    ) {
      this.iconListItems.push({
        id: 'retry_italian_invoicing_button',
        labelKey: 'booking_detail_italian_invoice_retry_button',
        icon: <RetryBookingIcon color={cssColors.listItem} />,
        handleClick: this.handleItalianInvoiceRetry
      });
    }
    if (integrationFailed) {
      this.iconListItems.push({
        id: 'external-integration-fix',
        labelKey: 'booking_detail_integration_external_fix_button',
        icon: <PaymentResettleIcon color={cssColors.listItem} />,
        handleClick: this.handleOpenFormFixIntegrationErrors,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });
    }

    if (
      ((bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE && carSharingFailed) || rrsFailed) &&
      (bookingStatus === STATUS_CANCELED || bookingStatus === BOOKING_STATUS_COMPLETED)
    )
      this.iconListItems.push({
        id: 'retry_rss_button',
        labelKey: 'booking_retry_rrs_button',
        icon: <RetryBookingIcon color={cssColors.listItem} />,
        handleClick: this.handleErrorRRS,
        disallowed: bookingEditRules.exclude,
        allowed: bookingEditRules.include
      });
    this.iconListItems.push({
      id: 'impersonate_button',
      labelKey: 'booking_detail_impersonate_button',
      icon: <PeopleIcon color={cssColors.listItem} />,
      handleClick: this.handleImpersonate,
      allowed: bookingEditRules.include,
      disallowed: bookingEditRules.exclude
    });
    this.iconListItems.push({
      id: 'invoice_settle_button',
      labelKey: 'booking_detail_settle_invoice',
      icon: <Add color={cssColors.listItem} />,
      handleClick: this.handleInvoiceModal,
      allowed: this.invoiceRules.include,
      disallowed: this.invoiceRules.exclude
    });
    this.iconListItems.push({
      id: 'events_history_button',
      labelKey: 'booking_events_history_detailed',
      icon: <History color={cssColors.listItem} />,
      handleClick: this.handleEventsHistory,
      disallowed: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY]
    });
  }

  handleErrorRRS() {
    const { dispatch, bookingId, bookingStatus, rrsFailed, carSharingFailed } = this.props;

    if (rrsFailed && carSharingFailed) {
      dispatch(retryUnitRequest(bookingId, bookingStatus)).then(() => {
        dispatch(retryRrsBooking(bookingId)).then(() => {
          setTimeout(this.updateBookingInfo, 1000);
        });
      });
    } else {
      if (carSharingFailed) this.handleRetryBooking();
      if (rrsFailed) this.handleRetryRrsBooking();
    }
  }

  handleRetryBooking() {
    const { dispatch, bookingId, bookingStatus } = this.props;
    dispatch(retryUnitRequest(bookingId, bookingStatus)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
    });
  }

  handleRetryRrsBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(retryRrsBooking(bookingId)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
    });
  }

  handleSettleBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(settleBooking(bookingId)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
      setTimeout(() => {
        if (!this.mounted) return false;
        dispatch(getBookingTransactions(bookingId, false));
      }, 2000);
    });
  }

  handleToggleUpdatePriceModal() {
    this.props.dispatch(toggleUpdatePriceModal());
  }

  handleUpdatePrice() {
    this.handleToggleUpdatePriceModal();
    this.props.dispatch(bookingUpdatePrice()).then(this.updateBookingInfo);
  }

  handleUpdatePriceCheck() {
    this.props.dispatch(bookingUpdatePriceCheck());
  }

  handleFinishBooking() {
    const { handleToggle, handleToggleFinish, dispatch } = this.props;
    if (handleToggle) handleToggleFinish();
    else dispatch(openFinishBookingModal());
  }

  handleVehicleChange() {
    const { bookingId, memberId, subCompanySelected, vehicleCompanyId } = this.props;

    this.props.dispatch(getBookingVehiclePlanning(bookingId)).then(data => {
      if (!isValidId(subCompanySelected.id)) {
        let params = {
          startDate: moment().toISOString(),
          periodInDays: 3,
          subCompanyId: vehicleCompanyId
        };
        this.props.dispatch(getDataVehiclePlanning(params));
      } else this.props.dispatch(getDataVehiclePlanning());

      const selectedMember = { id: memberId };
      const values = createInitVehiclePlanning(data, true);
      const newParams = enhanceSearchVehicleData(values, data, selectedMember, true);

      newParams.usages = [data.carSharingInfo.usageType];
      newParams.startParkingId = data.start.parking.id;

      this.props.dispatch(getBookingFindAvailableVehicle(newParams, false));
    });

    this.props.dispatch(toggleChangeVehicle());
  }

  handleMemberChange() {
    const { dispatch } = this.props;
    dispatch(toggleChangeMember({ skipConfirm: false }));
  }

  handleBookingStart() {
    const { dispatch } = this.props;
    dispatch(openStartBookingModal());
  }

  handleAbortFinishBooking() {
    this.props.dispatch(closeFinishBookingModal());
  }

  handleAbortStartBooking() {
    this.props.dispatch(closeStartBookingModal());
  }

  displayStartModal() {
    return (
      <Modal isOpen={toBoolean(this.props.openedStartModalBooking)} style={startBookingModalStyles}>
        <BookingStartForm
          cancel={this.handleAbortStartBooking}
          confirm={this.handleConfirmStartBooking}
          errorReq={this.startBookingFailed()}
        />
        {this.startBookingFailedMsg()}
      </Modal>
    );
  }

  startBookingFailedMsg() {
    const { error: errorObj } = this.props.startError;
    if (_has(errorObj, 'errorCode')) {
      return (
        <div className="error">
          <FormattedMessage id={_get(errorObj, 'errorCode')} />
        </div>
      );
    }
    if (_has(errorObj, 'developerMessage')) {
      return <div className="error">{_get(errorObj, 'developerMessage')}</div>;
    }
  }

  startBookingFailed() {
    const { error: errorObj } = this.props.startError;
    return safe(() => errorObj);
  }

  handleConfirmStartBooking() {
    const { dispatch, bookingId } = this.props;
    this.setState({ pendingStartBooking: true });
    dispatch(startBooking()).then(
      () => {
        dispatch(addFlashMessage({ contentKey: 'booking.start.success', type: FLASH_MESSAGE_TYPE_SUCCESS }));
        dispatch(getSingleBooking(bookingId, false));
        dispatch(closeStartBookingModal());
        this.setState({ pendingStartBooking: false });
      },
      error => {
        this.setState({ pendingStartBooking: false, startBookingFailed: true });
        dispatch({ type: BOOKING_START_ERROR, error });
        return dispatch(addErrorMessage({ error }));
      }
    );
  }

  displayMemberChange() {
    if (this.props.openedMemberChangeModal) {
      return <VehiclePlanningMemberChange />;
    }
  }

  handleConfirmFinishBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(closeFinishBookingModal());
    dispatch(requestBookingFinish(bookingId)).then(
      () => {
        this.updateBookingInfo();
        this.props.dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      () => {
        this.updateBookingInfo();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleExtendShortenBooking() {
    const { dispatch, handleToggle, booking, vehicle } = this.props;
    if (handleToggle) dispatch(toggleShortenExtendBooking(booking, vehicle, true));
    else dispatch(openExtendShortenBookingModal());
  }

  handleAbortExtendShortenBooking() {
    const { dispatch } = this.props;
    dispatch(closeExtendShortenBookingModal());
  }

  handleConfirmExtendShortenBooking(newEndDateObj) {
    const { dispatch, estimatedEnd, bookingId, registrationNumber } = this.props;
    const offset = moment.parseZone(estimatedEnd).utcOffset();
    const localeOffset = moment.parseZone().utcOffset();

    let endDate = moment
      .utc(newEndDateObj.newEndDate)
      .utcOffset(offset)
      .add(newEndDateObj.hour, 'hours')
      .add(newEndDateObj.min, 'minutes')
      .add(localeOffset - offset, 'minutes')
      .format('YYYY-MM-DDTHH:mmZ');

    dispatch(closeExtendShortenBookingModal());
    dispatch(requestBookingExtendShorten(bookingId, registrationNumber, endDate)).then(this.updateBookingInfo);
  }

  handleEditBooking() {
    const { dispatch, bookingId, handleToggle, openedEditBookingModal } = this.props;

    if (handleToggle) {
      if (!openedEditBookingModal) {
        dispatch(getBookingVehiclePlanning(bookingId)).then(data => dispatch(saveUserTypeForBookingEdit(data)));
      }
      dispatch(toggleVehiclePlanningEditBooking());
    } else dispatch(routeActions.push(routes.editBooking.path.replace(':bookingId', bookingId)));
  }

  handleCancelBooking() {
    const { dispatch, handleToggle, handleToggleDialog } = this.props;
    if (handleToggle) handleToggleDialog();
    else dispatch(openCancelBookingModal());
  }

  handleAbortCancelBooking() {
    const { dispatch } = this.props;
    dispatch(closeCancelBookingModal());
  }

  handleInvoiceCreation() {
    const { dispatch, bookingId } = this.props;
    this.handleInvoiceModal();
    dispatch(createInvoice(bookingId));
  }

  handleInvoiceModal() {
    const { dispatch } = this.props;
    dispatch(toggleCreateInvoiceBookingModal());
  }

  handleEventsHistory() {
    const { dispatch, bookingId, handleToggle } = this.props;
    if (handleToggle) alert('coming soon');
    else dispatch(routeActions.push(routes.bookingEventsHistory.path.replace(':bookingId', bookingId)));
  }

  handleInvoiceAddItem(item) {
    const { dispatch } = this.props;
    dispatch(addInvoiceItem(item));
  }

  handleInvoiceDeleteItem(item) {
    const { dispatch } = this.props;
    dispatch(deleteInvoiceItem(item));
  }

  showBookingError() {
    const { dispatch } = this.props;
    dispatch(
      addFlashMessage({
        contentKey: 'error_update_booking_info',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  updateBookingInfo() {
    const { dispatch, bookingId } = this.props;
    if (!this.mounted) return false;
    dispatch(getSingleBooking(bookingId, false)).then(null, this.showBookingError);
    dispatch(bookingViewGetInvoices(bookingId)).then(null, this.showBookingError);
  }

  handleConfirmCancelBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(closeCancelBookingModal());
    dispatch(requestBookingCancel(bookingId)).then(
      () => {
        this.updateBookingInfo();
        this.props.dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      () => {
        this.updateBookingInfo();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleImpersonate() {
    const { dispatch, memberId, companyId } = this.props;
    dispatch(getImpersonateMember(memberId, companyId));
  }

  handleOpenFormFixIntegrationErrors() {
    const { dispatch } = this.props;
    dispatch(openFixIntegrationErrorsModal());
  }

  handleCloseFormIntegrationErrors() {
    const { dispatch } = this.props;
    dispatch(closeFixIntegrationErrorsModal());
  }

  handleFixProplannerError() {
    const { dispatch, bookingId } = this.props;
    dispatch(fixProplannerError(bookingId, EXTERNAL_SYSTEM_LINKS.PROPLANNER));
  }

  toggleDepositUsage() {
    const { dispatch } = this.props;
    dispatch(toggleDepositUsage());
  }

  displayInvoiceCreationModal() {
    const { invoicesItems, totalPrices, useBookingDamageDeposit, intl } = this.props;
    const invoiceItemsWithTotal = invoicesItems.length ? addInvoiceItemsTotal(invoicesItems, totalPrices) : [];
    const currencySymbol = intl.formatMessage({ id: `unit_${this.props.companyCurrency}` });
    return (
      <Modal isOpen={Boolean(this.props.openedInvoiceCreationModal)} style={AddInvoiceModalStyles}>
        <Close
          onClick={this.handleInvoiceModal}
          style={{ float: 'right', cursor: 'pointer' }}
          color={'rgba(0, 0, 0, .54)'}
          hoverColor={'rgba(0, 0, 0, .84)'}
        />
        <h4>{intl.messages['create_invoice_button']}</h4>

        <CreateInvoiceForm onCallback={this.handleInvoiceAddItem} currency={this.props.companyCurrency} />

        {invoicesItems.length > 0 && (
          <div className="createInvoiceForm_container">
            <ul>
              <li className="header-invoice">
                <span className="desc" children={intl.messages['invoices_tableView_label_description']} />
                <span
                  children={
                    intl.messages['invoices_tableView_label_quantity'] +
                    ' * ' +
                    intl.formatMessage({ id: 'createInvoiceForm_priceWithVAT' }, { currency: currencySymbol })
                  }
                  className="quantity-unit"
                />
                <span
                  children={intl.formatMessage({ id: 'invoice_amount_with_currency' }, { currency: currencySymbol })}
                  className="price"
                />
              </li>
              {invoicesItems.map((item, index) => {
                return (
                  <li key={'dim' + index}>
                    <span children={item.description} className="desc" />
                    <span children={item.quantity + ' * ' + item.pricePerUnitWithVat} className="quantity-unit" />
                    <span children={item.itemPriceWithVat} className="price" />
                    <span className="delete-item">
                      <DeleteIcon
                        color={'rgba(0, 0, 0, .54)'}
                        style={{ cursor: 'pointer' }}
                        hoverColor={'rgba(0, 0, 0, .84)'}
                        onClick={() => {
                          this.handleInvoiceDeleteItem(item);
                        }}
                      />
                    </span>
                  </li>
                );
              })}
              {invoiceItemsWithTotal.map(item => {
                if (_get(item, 'translateKey') === 'TOTAL') {
                  return (
                    <li key="globalPrice" className="footer-invoice">
                      <span className="desc" children={intl.messages['common_price_with_vat']} />
                      <span className="amount" children={item.itemPriceWithVat} />
                      <span className="delete-item" />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}

        {invoicesItems.length > 0 && (
          <div>
            <hr />
            <Checkbox
              label={<FormattedMessage id="invoice_booking_deposit_usage" />}
              id="useBookingDamageDeposit"
              checked={useBookingDamageDeposit}
              onCheck={() => this.toggleDepositUsage()}
              customClass="addBookingConfirmationForm_option"
            />
            <EkButton
              type="button"
              formRowItemKey="createInvoiceForm_submitBtn"
              customClass="createInvoiceForm_actionsButton"
              onAction={this.handleInvoiceCreation}
            >
              <FormattedMessage id="invoice_create_generate_button" />
            </EkButton>
          </div>
        )}
        <EkButton type="button" skinType="reverse" customClass="createInvoiceForm_actionsButton" onAction={this.handleInvoiceModal}>
          <FormattedMessage id="common_cancel" />
        </EkButton>
      </Modal>
    );
  }

  displayCancelModal() {
    return (
      <Modal isOpen={Boolean(this.props.openedCancelModalBooking)} style={modalStyles}>
        <p>
          <FormattedMessage id="booking_detail_cancelBooking_text" />
        </p>
        <EkButton
          type="button"
          skinType="reverse"
          customClass="bookingView_cancelBooking_abort_button"
          onAction={this.handleAbortCancelBooking}
        >
          <FormattedMessage id="common_no" />
        </EkButton>
        <EkButton type="button" customClass="bookingView_cancelBooking_confirm_button" onAction={this.handleConfirmCancelBooking}>
          <FormattedMessage id="common_yes" />
        </EkButton>
      </Modal>
    );
  }

  displayExtendShortenModal() {
    return (
      <Modal isOpen={Boolean(this.props.openedExtendShortenModalBooking)} style={extendShortenModalStyles}>
        <ExtendShortenBookingForm
          currentEndDate={this.props.estimatedEnd}
          onCallback={this.handleConfirmExtendShortenBooking}
          onAbort={this.handleAbortExtendShortenBooking}
        />
      </Modal>
    );
  }

  displayFixIntegrationErrorsModal() {
    return (
      <Modal isOpen={Boolean(this.props.openedFixIntegrationErrorsModalBooking)} style={extendShortenModalStyles}>
        <h4>
          <Close
            onClick={this.handleCloseFormIntegrationErrors}
            style={{ float: 'right', cursor: 'pointer' }}
            color={'rgba(0, 0, 0, .54)'}
            hoverColor={'rgba(0, 0, 0, .84)'}
          />
          <FormattedMessage id="proplanner-title-fix-form" />
        </h4>
        <BookingFixIntegrationErrorsForm onCallback={this.handleFixProplannerError} onAbort={this.handleCloseFormIntegrationErrors} />
      </Modal>
    );
  }

  displayVehicleChangeModal() {
    if (Boolean(this.props.openedChangeVehicleModal)) {
      return <VehiclePlanningChangeVehicle />;
    }
  }

  displayFinishModal() {
    return (
      <Modal isOpen={Boolean(this.props.openedFinishModalBooking)} style={modalStyles}>
        <FinalizeBookingForm onCallback={this.handleConfirmFinishBooking} onAbort={this.handleAbortFinishBooking} />
      </Modal>
    );
  }

  displayUpdatePriceModal() {
    return (
      <Modal isOpen={Boolean(this.props.openedUpdatePriceModal)} style={updateBookingPriceFormStyle}>
        <UpdateBookingPriceForm
          currency={this.props.bookingCurrency}
          onCallback={this.handleUpdatePrice}
          onCallbackCheck={this.handleUpdatePriceCheck}
          onAbort={this.handleToggleUpdatePriceModal}
        />
      </Modal>
    );
  }

  getDriverInfo() {
    let { detailMember } = this.props;
    if (_get(detailMember, 'drivingLicence'))
      return <MemberDriverInfo cName="driving-license-detail" detailMember={detailMember} drivingLicenseFiles={this.props.bookingFiles} />;
  }

  closeButton() {
    const { handleToggle } = this.props;
    if (handleToggle) {
      return (
        <IconButton onClick={handleToggle}>
          <Close color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} />
        </IconButton>
      );
    }
  }

  render() {
    return (
      <div className="mainContainer_content">
        <div className="pageContainer">
          {!this.props.handleToggle && <BackLink link={this.backToListURL} labelKey="back_link_bookings_list" />}
          <div className={classnames('bookingView detailView', { tile: this.props.handleToggle })}>
            {this.displayCancelModal()}
            {this.displayExtendShortenModal()}
            {this.displayFinishModal()}
            {this.displayStartModal()}
            {this.displayInvoiceCreationModal()}
            {this.displayMemberChange()}
            {this.displayUpdatePriceModal()}
            {this.displayVehicleChangeModal()}
            {this.displayFixIntegrationErrorsModal()}
            <div className="detailView_header">
              <div className="detailView_header_left">
                <Tooltip content={<FormattedMessage id="common_booking" />} placement="bottom">
                  <DirectionsCarIcon style={DetailViewStyles.icons.header} />
                </Tooltip>
                <h3 className="detailView_header_text">
                  <Tooltip content={<FormattedMessage id="booking_detail_type" />} placement="bottom" trigger="click">
                    <span>{this.bookingType}</span>
                  </Tooltip>
                  <span className={`detailView_header_status ${this.bookingStatusClass}`}>
                    <Tooltip content={<FormattedMessage id="common_booking_status" />} placement="bottom">
                      <span>{this.bookingStatus}</span>
                    </Tooltip>
                  </span>
                </h3>
              </div>
              <div className="detailView_header_right">
                {this.props.enableNewUi && (
                  <a style={{ margin: '10px' }} target="_blank" href={`/#/v2/booking-detail/${this.props.bookingId}`}>
                    v2
                  </a>
                )}
                <IconList items={this.iconListItems} />
                {this.closeButton()}
              </div>
            </div>
            <div className="detailView_body">
              <BookingInfo
                openToRideSharing={Boolean(this.props.openToRideSharing)}
                rrsFailed={Boolean(this.props.rrsFailed || this.props.carSharingFailed)}
                italianInvoiceError={this.props.italianInvoiceError}
                dmsInvoiceErrors={this.props.dmsInvoiceErrors}
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                companyName={this.props.companyName}
                bookingId={this.props.bookingId}
                bookingUsage={this.props.bookingUsage}
                companyId={this.props.companyId}
                memberId={this.props.memberId}
                reservedSeats={this.props.reservedSeats}
                vehicleSeats={this.props.vehicleSeats}
                ownerFirstName={this.props.ownerFirstName}
                ownerLastName={this.props.ownerLastName}
                ownerBookingId={this.props.ownerBookingId}
                bookingMemberType={this.props.bookingMemberType}
                integrationFailed={this.props.integrationFailed}
                integrationErrors={this.props.integrationErrors}
              />
              <BookingMoreInfo bookingCustomValues={this.props.bookingCustomValues} />
              <BookingVehicleInfo
                vehicleModel={this.props.vehicleModel}
                vehicleBrand={this.props.vehicleBrand}
                vehicleId={this.props.vehicleId}
                vehicleCompanyId={this.props.vehicleCompanyId}
                vehicleCompanyName={this.props.vehicleCompanyName}
                registrationNumber={this.props.registrationNumber}
                vehicleCategoryType={this.props.vehicleCategoryType}
                startMileage={this.props.startMileage}
                endMileage={this.props.endMileage}
                startFuel={this.props.startFuel}
                endFuel={this.props.endFuel}
              />
              <BookingStatusHistory statusHistory={this.props.statusHistory} locale={this.props.locale} />
              <BookingTripInfo
                estimatedStart={this.props.estimatedStart}
                effectiveStart={this.props.effectiveStart}
                bookingStartParking={this.props.bookingStartParking}
                bookingStartSite={this.props.bookingStartSite}
                bookingStartAddress={this.props.bookingStartAddress}
                bookingEndParking={this.props.bookingEndParking}
                bookingEndSite={this.props.bookingEndSite}
                bookingEndAddress={this.props.bookingEndAddress}
                estimatedEnd={this.props.estimatedEnd}
                effectiveEnd={this.props.effectiveEnd}
                bookingStartMileage={this.props.startMileage}
                bookingEndMileage={this.props.endMileage}
                startFuel={this.props.startFuel}
                endFuel={this.props.endFuel}
              />
              <TripMoreInfo pickupAdress={this.props.pickupAdress} pickupDate={this.props.pickupDate} comment={this.props.comment} />
              <BookingBilling
                statusPayment={this.props.statusPayment}
                bookingUsageType={this.props.bookingUsageType}
                freeOfCharges={this.props.freeOfCharges}
                invoiceNumber={this.props.invoiceNumber}
                currency={this.props.bookingCurrency}
                estimatedPriceForDuration={this.props.estimatedPriceForDuration}
                totalPriceIncludingTaxes={this.props.totalPriceIncludingTaxes}
                freeKmPerDay={this.props.freeKmPerDay}
                voucherDiscountAmount={this.props.voucherDiscountAmount}
                bookingInvoices={this.props.bookingInvoices}
                automaticPaymentRetry={this.props.automaticPaymentRetry}
              />
              <BookingTransactionInfo transactions={this.props.transactions} locale={this.props.locale} />
              {this.getDriverInfo()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default namedCompose(
  injectIntl,
  connect(state => {
    const enableNewUi = _get(state.user, 'enableNewUi');
    const bookingDetail = _get(state.bookings, 'bookingDetail');
    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    const rideSharingInfo = _get(bookingDetail, 'rideSharingInfo');
    const integrationFailed = _get(bookingDetail, 'integrationFailed');
    const rrsSharingInfo = _get(bookingDetail, 'rrsSharingInfo');
    const openedMemberChangeModal = _get(state.vehiclePlanning, 'openedMemberChangeModal');
    const bookingMember = _get(bookingDetail, 'member');
    const bookingStart = _get(bookingDetail, 'start');
    const bookingEnd = _get(bookingDetail, 'end');
    const bookingStartParkingObject = _get(bookingStart, 'parking');
    const bookingEndParkingObject = _get(bookingEnd, 'parking');
    const memberCompany = _get(bookingMember, 'company');
    const rideSharingOwner = _get(rideSharingInfo, 'owner');
    const carSharingCost = _get(carSharingInfo, 'cost');
    const carSharingPickup = _get(carSharingInfo, 'pickUp');
    const startVehicleStatusData = _get(carSharingInfo, 'startVehicleStatusData');
    const endVehicleStatusData = _get(carSharingInfo, 'endVehicleStatusData');
    const vehicleObject = _get(bookingDetail, 'vehicle');
    const vehicleModelObject = _get(vehicleObject, 'version.model');
    const detailMember = { drivingLicence: _get(bookingDetail, 'drivingLicenceMetaData') };
    const startError = _get(state.bookings, 'startError');

    const effectiveStart = getBookingInfo(bookingDetail, 'effectiveStartDate');
    const effectiveEnd = getBookingInfo(bookingDetail, 'effectiveEndDate');
    const openedChangeVehicleModal = _get(state.vehiclePlanning, 'openedChangeVehicleModal');
    const { subCompanySelected } = state.subCompanies || {};
    const integrationErrors = _get(state.bookings, 'bookingExternalErrors');

    return {
      locale: localeSelector(state),
      bundle: bundleSelector(state),
      apiToken: _get(state.api, 'token'),
      transactions: _get(state.bookings, 'bookingTransactions'),
      bookingInvoices: _get(state.bookings, 'bookingInvoices'),
      bookingFiles: _get(state.bookings, 'bookingFiles'),
      urlParams: _get(state.bookings, 'urlParams'),
      openedInvoiceCreationModal: _get(state.bookings, 'openedInvoiceCreationModal'),
      openedCancelModalBooking: _get(state.bookings, 'openedCancelModalBooking'),
      openedExtendShortenModalBooking: _get(state.bookings, 'openedExtendShortenModalBooking'),
      openedFinishModalBooking: _get(state.bookings, 'openedFinishModalBooking'),
      openedUpdatePriceModal: _get(state.bookings, 'openedUpdatePriceModal'),
      openedStartModalBooking: _get(state.bookings, 'openedStartModalBooking'),
      openedFixIntegrationErrorsModalBooking: _get(state.bookings, 'openedFixIntegrationErrorsModalBooking'),
      bookingMemberType: _get(bookingDetail, 'memberType.name'),
      bookingStatus: _get(bookingDetail, 'status'),
      bookingType: _get(bookingDetail, 'type'),
      bookingCurrency: _get(bookingDetail, 'currency'),
      bookingId: _get(bookingDetail, 'id'),
      bookingUsage: _get(bookingDetail, 'vehicleUsageAtBookingCreation'),
      reservedSeats: _get(bookingDetail, 'reservedSeats'),
      bookingCustomValues: _get(bookingDetail, 'bookingCustomValues'),
      comment: _get(bookingDetail, 'comment'),
      statusHistory: _get(bookingDetail, 'allStatuses'),
      paymentResettlingAllowed: _get(bookingDetail, 'paymentResettlingAllowed'),
      drivingLicenceMetaData: _get(bookingDetail, 'drivingLicenceMetaData'),
      bookingUsageType: _get(carSharingInfo, 'usageType'),
      openToRideSharing: _get(carSharingInfo, 'openToRideSharing'),
      statusPayment: _get(carSharingInfo, 'statusPayment'),
      freeOfCharges: _get(carSharingInfo, 'freeOfCharges'),
      invoiceNumber: _get(carSharingInfo, 'invoiceNumber'),
      rrsFailed: _get(rrsSharingInfo, 'rrsUpdateFailed'),
      carSharingFailed: _get(carSharingInfo, 'failed'),
      automaticPaymentRetry: _get(carSharingInfo, 'automaticPaymentRetry'),
      firstName: _get(bookingMember, 'firstName'),
      lastName: _get(bookingMember, 'lastName'),
      memberId: _get(bookingMember, 'id'),
      companyCurrency: _get(memberCompany, 'currency'),
      companyName: _get(memberCompany, 'name'),
      companyId: _get(memberCompany, 'id'),
      ownerFirstName: _get(rideSharingOwner, 'firstname'),
      ownerLastName: _get(rideSharingOwner, 'lastname'),
      ownerBookingId: _get(rideSharingInfo, 'bookingId'),
      estimatedPriceForDuration: _get(carSharingCost, 'estimatedPriceForDuration'),
      totalPriceIncludingTaxes: _get(carSharingCost, 'totalPriceIncludingTaxes'),
      freeKmPerDay: _get(carSharingCost, 'freeKmPerDay'),
      voucherDiscountAmount: _get(carSharingCost, 'voucherDiscountAmount'),
      estimatedStart: _get(bookingStart, 'date'),
      bookingStartAddress: _get(bookingStart, 'address.formattedAddress'),
      estimatedEnd: _get(bookingEnd, 'date'),
      bookingEndAddress: _get(bookingEnd, 'address.formattedAddress'),
      bookingStartParking: _get(bookingStartParkingObject, 'name'),
      bookingStartSite: _get(bookingStartParkingObject, 'site.name'),
      bookingEndParking: _get(bookingEndParkingObject, 'name'),
      bookingEndSite: _get(bookingEndParkingObject, 'site.name'),
      effectiveStart: effectiveStart,
      effectiveEnd: effectiveEnd,
      pickupAdress: _get(carSharingPickup, 'address.formattedAddress'),
      pickupDate: _get(carSharingPickup, 'date'),
      vehicleModel: _get(vehicleModelObject, 'name'),
      vehicleBrand: _get(vehicleModelObject, 'brand.name'),
      vehicleId: _get(vehicleObject, 'id'),
      vehicleSeats: _get(vehicleObject, 'seats'),
      vehicleCompanyId: _get(vehicleObject, 'lastPosition.parking.site.subCompanyId'),
      vehicleCategoryType: _get(vehicleObject, 'category.type'),
      registrationNumber: _get(vehicleObject, 'registrationNumber'),
      vehicleCompanyName: _get(state.bookings, 'vehicleCompanyName'),
      startMileage: _get(startVehicleStatusData, 'mileage'),
      startFuel: _get(startVehicleStatusData, 'fuel'),
      endMileage: _get(endVehicleStatusData, 'mileage'),
      endFuel: _get(endVehicleStatusData, 'fuel'),
      invoicesItems: _get(state.invoices, 'invoiceItems'),
      useBookingDamageDeposit: _get(state.invoices, 'useBookingDamageDeposit'),
      totalPrices: _get(state.invoices, 'totalPrices'),
      italianInvoiceError: _get(state.invoices, 'italianInvoiceError'),
      dmsInvoiceErrors: safe(() => state.invoices.dmsInvoiceErrors),
      detailMember,
      enableNewUi,
      openedMemberChangeModal,
      openedChangeVehicleModal,
      subCompanySelected,
      startError,
      integrationFailed,
      integrationErrors
    };
  })
)(BookingView);
