import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import EkRadio from '../EkRadio/EkRadio';
import { booleanOptions } from '../../constants/options-constants';

class BookingFixIntegrationErrorsForm extends Component {
  render() {
    const {
      fields: { fixed, comment },
      handleSubmit
    } = this.props;

    return (
      <div className="bookingFixIntegrationErrorsForm_container">
        <form action="#" className="bookingFixIntErrForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <FormRow customWrapperClass="bookingFixIntErrForm_row hide">
            <EkRadio
              formRowItemKey="companyForm_identityDocumentRequired"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="proplanner_error_fixed_field"
              items={booleanOptions}
              id={'bookingFixIntErrForm'}
              namespace="bookingFixIntErrForm"
              field={fixed}
            />
          </FormRow>
          <FormRow customWrapperClass="bookingFixIntErrForm_row">
            <BoxedInput
              formRowItemKey="bookingFixIntErrForm_quantity"
              type="text"
              id="bookingFixIntErrForm_comment"
              labelKey="proplanner_error_comment_field"
              field={comment}
            />
          </FormRow>
          <FormRow>
            <EkButton skinType="reverse" customClass="bookingFixIntErrForm_actionsButton" onAction={this.props.onAbort}>
              <FormattedMessage id="common_cancel" />
            </EkButton>
            <EkButton customClass="bookingFixIntErrForm_actionsButton pull-right" onAction={handleSubmit(this.props.onCallback)}>
              <FormattedMessage id="proplanner_error_submit_btn" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

BookingFixIntegrationErrorsForm.displayName = 'BookingFixIntegrationErrorsForm';

BookingFixIntegrationErrorsForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

BookingFixIntegrationErrorsForm = reduxForm({
  form: 'BookingFixIntegrationErrorsForm',
  fields: ['fixed', 'comment'],
  initialValues: { fixed: 'true', comment: '' }
})(BookingFixIntegrationErrorsForm);

export default BookingFixIntegrationErrorsForm;
