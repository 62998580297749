/*eslint-disable react/no-set-state */

import React, { PropTypes, Component } from 'react';
import PdfIcon from 'material-ui/svg-icons/image/picture-as-pdf';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import _get from 'lodash/get';
import { FileImageStyles } from '../../constants/style-constants';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { base64ToMimeType, downloadBase64, isInternetExplorer11, namedCompose, newProps, openPdfNewTab } from '../../utils/utils';
import GetAppIcon from 'material-ui/svg-icons/action/get-app';
import classNames from 'classnames';
import ImagePreview from '../ImagePreview/ImagePreview';
import { connect } from 'react-redux';
import { bundleSelector } from '../../selectors/all-selectors';
import ReactSVG from 'react-svg';
import { NEW_TAB_ICON_PATH } from '../../constants/generic-constants';

class FileImage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isOpen: false, loadReady: false };
    this.derivedStateFromProps(props, this.state, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillUpdate(props, state) {
    this.derivedStateFromProps(props, state);
  }

  derivedStateFromProps(props, state, init) {
    const { imageObject } = props;
    if (newProps(props, this.props, init, 'imageObject')) {
      const imageContent = _get(imageObject, 'content', '');
      const imageType = _get(imageObject, 'mimeType', '');

      if (imageContent) {
        if (imageContent.substring(0, 4) === 'data') this.imageData = imageContent;
        else this.imageData = `data:${imageType};base64,${imageContent}`;

        if (imageType === 'application/pdf' || base64ToMimeType(imageContent) === 'application/pdf') this.setPdfType();
        else this.setImageType();
      } else this.setLoaderType();
    }

    this.downloadClass = 'icon_download';
    this.loadStatusClass = this.downloadClass + (state.loadReady ? '_visible' : '_hidden');
  }

  setLoaderType() {
    this.dataType = 'loader';
    this.getElement = this.getLoader;
  }

  setPdfType() {
    this.dataType = 'pdf';
    this.getElement = this.getPdf;
  }

  setImageType() {
    this.dataType = 'image';
    this.getElement = this.getImage;
  }

  handleDownloadPdf() {
    const { imageObject } = this.props;
    downloadBase64(imageObject);
    this.setState({ loadReady: false });
    setTimeout(() => {
      if (this.state.isOpen) this.setState({ loadReady: true });
    }, 1000);
  }

  getImage() {
    const key = 'common_broken_image';
    const defaultMessage = 'Could not load the image';
    const brokenImage = _get(this.props.bundle, key, defaultMessage);

    return (
      <div className="imageWrap_closed">
        <ImagePreview
          src={this.imageData}
          alt={brokenImage}
          thumbnailStyle={this.props.imageStyle}
          srcStyle={{
            maxHeight: 90 + 'vh',
            maxWidth: 90 + 'vw',
            cursor: 'default'
          }}
        />
      </div>
    );
  }

  getPdfOpen() {
    if (this.state.isOpen && this.dataType === 'pdf')
      return (
        <div className="pdfWrap_viewer">
          <iframe src={this.imageData} />
          <div className={classNames(this.downloadClass, this.loadStatusClass)} onClick={this.handleDownloadPdf}>
            <div className="icon_wrap">
              <GetAppIcon className="icon_class" />
            </div>
          </div>
          <div className="icon_pdf" onClick={this.handleClose}>
            <div className="icon_wrap">
              <CloseIcon className="icon_class" />
            </div>
          </div>
        </div>
      );
  }

  handleClickExternal(e) {
    e.stopPropagation();
    if (this.imageData) {
      openPdfNewTab(this.imageData);
    }
  }

  openNewTabIcon() {
    if (!isInternetExplorer11() && this.props.showNewTabIcon) {
      return (
        <div className="external-link-icon" onClick={this.handleClickExternal}>
          <ReactSVG src={NEW_TAB_ICON_PATH} />
        </div>
      );
    }
  }

  getPdf() {
    return (
      <div className="pdfWrap">
        <div style={this.props.imageStyle} className="pdfWrap_placeholder" onClick={this.handleOpen}>
          {this.openNewTabIcon()}
          <PdfIcon style={FileImageStyles.icons.pdf} />
        </div>
      </div>
    );
  }

  getLoader() {
    return (
      <div className="saving_wrap">
        <div className="saving_placeholder">
          <p className="saving">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        </div>
      </div>
    );
  }

  handleOpen() {
    if (this.dataType === 'pdf' && isInternetExplorer11()) this.handleDownloadPdf();
    else this.setState({ isOpen: true, loadReady: true });
  }

  handleClose() {
    this.setState({ isOpen: false, loadReady: false });
  }

  render() {
    return (
      <div className={`fileImage fileImage_type_${this.dataType}`}>
        {this.getElement()}
        {this.getPdfOpen()}
      </div>
    );
  }
}

// ---

FileImage.defaultProps = {
  showNewTabIcon: true
};

FileImage.propTypes = {
  imageObject: PropTypes.object,
  imageStyle: PropTypes.object,
  showNewTabIcon: PropTypes.bool
};

// ---

export default namedCompose(
  connect(state => {
    return {
      bundle: bundleSelector(state)
    };
  }),
  pure
)(FileImage);
