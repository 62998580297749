module.exports={
  "cs-i18n.json": "cs-i18n-eeb5d45c48.json",
  "da-i18n.json": "da-i18n-7985d6306b.json",
  "de-i18n.json": "de-i18n-8ac84fe16d.json",
  "en-i18n.json": "en-i18n-ddaab1f8ff.json",
  "es-i18n.json": "es-i18n-88b46d4905.json",
  "fr-i18n.json": "fr-i18n-7b35961778.json",
  "it-i18n.json": "it-i18n-d3a6f2aa8c.json",
  "nl-i18n.json": "nl-i18n-c57bb64ff6.json",
  "pl-i18n.json": "pl-i18n-dbecd2ba3e.json",
  "pt-i18n.json": "pt-i18n-5bae93584d.json",
  "pt_BR-i18n.json": "pt_BR-i18n-5136360d22.json",
  "sk-i18n.json": "sk-i18n-b7ec3b7e7e.json",
  "sv-i18n.json": "sv-i18n-28bfbc00af.json"
}