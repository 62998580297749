import React, { Component } from 'react';
import { headerContractBookingValuesSelector, currentCompanyContractSelector } from '../../../../selectors/all-selectors';
import LabeledCheckbox from '../../../../components/LabeledCheckbox/LabeledCheckbox';
import { reduxForm } from 'redux-form';
import _get from 'lodash/get';
import { scrollToFirstError } from '../../../../utils/utils';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { condition as con, createValidator, onlyTrue, stopValidationIf } from '../../../../validation/sync-validation';

class BookingSettings extends Component {
  componentWillMount() {
    this.onDamageUncheck = value => {
      const { damageWithPhoto, damageExtraPhoto, dekra } = this.props.fields.booking;

      if (!value) {
        if (damageWithPhoto.value) damageWithPhoto.onChange(false);
        if (damageExtraPhoto.value) damageExtraPhoto.onChange(false);
        if (dekra.value) dekra.onChange(false);
      }
    };
  }

  addErrorToLabel(labelId, field = {}) {
    return (
      <span>
        {getMsg(labelId)}
        {field.error && <span className="sc-error-star">*</span>}
      </span>
    );
  }

  addErrorDesc() {
    if (this.props.invalid) {
      return (
        <div className="sc-error-desc">
          {getMsg('validation_field_missing')}
          <span className="sc-error-star">*</span>
        </div>
      );
    }
  }

  render() {
    const {
      fields: { businessCarSharing, privateCarSharing, rideSharing, preBooking, interfaceConfigDto, booking },
      limitedAccess
    } = this.props;

    return (
      <div className="contract-booking-info">
        <div className="sc-option-details">
          <div className="sc-category">
            <div className="contractForm_definitionList_term">
              {getMsg('member_subscription_origin_web_form')} / {getMsg('member_subscription_origin_mobile_form')}
            </div>
            <div className="sc-boxes">
              <LabeledCheckbox id="businessCarSharing" field={businessCarSharing} disabled={limitedAccess}>
                {this.addErrorToLabel('contractForm_businessCarSharing', businessCarSharing)}
              </LabeledCheckbox>
              <LabeledCheckbox id="privateCarSharing" field={privateCarSharing} disabled={limitedAccess}>
                {this.addErrorToLabel('contractForm_privateCarSharing', privateCarSharing)}
              </LabeledCheckbox>
              <LabeledCheckbox id="replacementVehicleDisplay" field={interfaceConfigDto.replacementVehicleDisplay} disabled={limitedAccess}>
                <FormattedMessage id="contractForm_replacementVehicleDisplay" />
              </LabeledCheckbox>
              <LabeledCheckbox id="memberPreBooking" field={booking.memberPreBooking} disabled={limitedAccess}>
                <FormattedMessage id="label.pre.booking.by.member" />
              </LabeledCheckbox>
              <LabeledCheckbox id="rideSharing" field={rideSharing} disabled={limitedAccess}>
                <FormattedMessage id="contractForm_rideSharing" />
              </LabeledCheckbox>
              <LabeledCheckbox id="oneWayDisplay" field={interfaceConfigDto.oneWayDisplay} disabled={limitedAccess}>
                {this.addErrorToLabel('contractForm_oneWayDisplay', interfaceConfigDto.oneWayDisplay)}
              </LabeledCheckbox>
              <LabeledCheckbox id="returnTripDisplay" field={interfaceConfigDto.returnTripDisplay} disabled={limitedAccess}>
                {this.addErrorToLabel('contractForm_returnTripDisplay', interfaceConfigDto.returnTripDisplay)}
              </LabeledCheckbox>
            </div>
          </div>
          <div className="sc-category">
            <div className="contractForm_definitionList_term">
              <FormattedMessage id="common.back.office" />
            </div>
            <div className="sc-boxes">
              <LabeledCheckbox id="preBooking" field={preBooking} disabled={limitedAccess}>
                <FormattedMessage id="contractForm_preBooking" />
              </LabeledCheckbox>
              <LabeledCheckbox id="bookingCustomFields" field={booking.customFields} disabled={limitedAccess}>
                <FormattedMessage id="company_detail_tab_booking_custom_fields" />
              </LabeledCheckbox>
              <LabeledCheckbox id="smartcards" field={booking.smartcards} disabled={limitedAccess}>
                <FormattedMessage id="side_menu_section_users_smartcards" />
              </LabeledCheckbox>
              <LabeledCheckbox id="bookingProPlanner" field={booking.proplanner} disabled={limitedAccess}>
                <FormattedMessage id="company_detail_tab_booking_proplanner" />
              </LabeledCheckbox>
              <LabeledCheckbox id="damageReport" onCheck={this.onDamageUncheck} field={booking.damageReport} disabled={limitedAccess}>
                <FormattedMessage id="vehicle_tab_vehicleDamages" />
              </LabeledCheckbox>
              {booking.damageReport.value && (
                <div className="sc-option-tab">
                  <LabeledCheckbox id="damageWithPhoto" field={booking.damageWithPhoto} disabled={limitedAccess}>
                    <FormattedMessage id="companyForm_damageReportPhotos_label" />
                  </LabeledCheckbox>
                  <LabeledCheckbox id="damageExtraPhoto" field={booking.damageExtraPhoto} disabled={limitedAccess}>
                    <FormattedMessage id="label.additional.photo" />
                  </LabeledCheckbox>
                  <LabeledCheckbox id="dekra" field={booking.dekra} disabled={limitedAccess}>
                    <span>Dekra</span>
                  </LabeledCheckbox>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.addErrorDesc()}
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={this.props.handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

const fieldChecked = field => (props, value, values) => {
  return _get(values, field);
};

BookingSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractBookingSettings',
    fields: [
      'businessCarSharing',
      'privateCarSharing',
      'rideSharing',
      'preBooking',
      'flexibleSearch',
      'interfaceConfigDto.oneWayDisplay',
      'interfaceConfigDto.returnTripDisplay',
      'interfaceConfigDto.replacementVehicleDisplay',
      'booking.customFields',
      'booking.damageReport',
      'booking.damageWithPhoto',
      'booking.damageExtraPhoto',
      'booking.dekra',
      'booking.smartcards',
      'booking.memberPreBooking',
      'booking.proplanner'
    ],
    validate: createValidator({
      businessCarSharing: [stopValidationIf(con(fieldChecked('privateCarSharing'))), onlyTrue()],
      privateCarSharing: [stopValidationIf(con(fieldChecked('businessCarSharing'))), onlyTrue()],
      'interfaceConfigDto.oneWayDisplay': [stopValidationIf(con(fieldChecked('interfaceConfigDto.returnTripDisplay'))), onlyTrue()],
      'interfaceConfigDto.returnTripDisplay': [stopValidationIf(con(fieldChecked('interfaceConfigDto.oneWayDisplay'))), onlyTrue()]
    })
  },
  state => {
    const contract = currentCompanyContractSelector(state);
    const businessDefault = 'boolean' === typeof contract.businessCarSharing ? contract.businessCarSharing : true;

    return {
      initialValues: {
        businessCarSharing: businessDefault,
        privateCarSharing: contract.privateCarSharing,
        preBooking: contract.preBooking,
        rideSharing: contract.rideSharing,
        interfaceConfigDto: contract.interfaceConfigDto,
        booking: headerContractBookingValuesSelector(state)
      }
    };
  }
)(BookingSettings);

export default BookingSettings;
