import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import { bookingStatus, bookingTypes, booleanOptions } from '../../../../constants/options-constants';
import { vehicleUsages } from '../../../../constants/filters-constants';
import { fallbackFunc, isEmpty, safe } from '../../../../utils/utils';
import { getModelsList } from '../../../../actions/models-actions';
import cs from 'classnames';

import {
  createNameToIdMap,
  selectVehicleBrandsOptions,
  selectVehicleModelsOptions,
  vehicleBrandsLoadingSelector,
  vehicleBrandsSelector,
  vehicleModelsLoadingSelector
} from '../../../../selectors/all-selectors';

class Filters extends Component {
  setFallbacks() {
    this.updateModels = name => {
      const { props: p } = this;
      const brandId = safe(() => createNameToIdMap(p.vehicleBrands)[name]);

      if (brandId) {
        p.dispatch(getModelsList(brandId, false)).then(() => {
          p.fields.vehicleModel.onChange('');
        }, fallbackFunc);
      }
    };
  }

  setActiveBlocks() {
    const { fields: f } = this.props;

    this.bookingBlockActive = this.fieldsActive(
      f.status,
      f.vehicleUsageAtBookingCreation,
      f.type,
      f.bookingId,
      f.startDateMin,
      f.startDateMax,
      f.endDateMin,
      f.endDateMax
    );

    this.filtersBlockActive = this.fieldsActive(f.memberFirstName, f.memberLastName, f.memberLogin);
    this.vehicleBlockActive = this.fieldsActive(f.vehicleBrand, f.vehicleModel, f.vehicleRegistrationNumber);
    this.voucherBlockActive = this.fieldsActive(f.voucherCode, f.voucherGroupName, f.voucherGroupIds);
    this.errorBlockActive = this.fieldsActive(f.failed, f.integrationFailed, f.rrsUpdateFailed, f.delayed);
  }

  componentWillMount() {
    this.setFallbacks();
    this.setActiveBlocks();
  }

  fieldsActive(...fields) {
    for (let i = 0; i < fields.length; i++) {
      if (!isEmpty(fields[i].initialValue)) {
        return true;
      }
    }
  }

  render() {
    const { props: p } = this;
    const { fields: f } = p;

    return (
      <form className="bookings-filters">
        <div className={cs('sc-block', { active: this.filtersBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="booking_view_member_tile_header" />
          </div>
          <div className="sc-items">
            <BoxedInput type="text" labelKey="backUsers_detail_first_name" field={f.memberFirstName} />
            <BoxedInput type="text" labelKey="backUsers_detail_last_name" field={f.memberLastName} />
            <BoxedInput type="text" labelKey="backUsers_detail_email" field={f.memberLogin} />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.vehicleBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="vehicle_tab_vehicle" />
          </div>
          <div className="sc-items">
            <BoxedSelect
              labelKey="vehiclesFiltersForm_brand"
              options={p.vehicleBrandsOptions}
              field={f.vehicleBrand}
              onChange={this.updateModels}
              disabled={p.vehicleBrandsLoading}
            />
            <BoxedSelect
              labelKey="vehiclesFiltersForm_model"
              options={p.vehicleModelsOptions}
              field={f.vehicleModel}
              disabled={p.vehicleModelsLoading}
            />
            <BoxedInput type="text" labelKey="vehiclesFiltersForm_plateNumber" field={f.vehicleRegistrationNumber} />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.bookingBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_booking" />
          </div>
          <div className="sc-items">
            <BoxedSelect labelKey="bookings_tableView_label_status" options={bookingStatus} field={f.status} />
            <BoxedSelect labelKey="vehiclesFiltersForm_usage" options={vehicleUsages} field={f.vehicleUsageAtBookingCreation} />
            <BoxedSelect labelKey="common_type" options={bookingTypes} field={f.type} />
            <BoxedInput type="text" labelKey="common_booking_id" field={f.bookingId} />
          </div>
          <div className="sc-items">
            <BoxedInput type="date" skinType="date" field={f.startDateMin} labelKey="booking_start_date_after" />
            <BoxedInput type="date" skinType="date" field={f.startDateMax} labelKey="booking_start_date_before" />
          </div>
          <div className="sc-items">
            <BoxedInput type="date" skinType="date" field={f.endDateMin} labelKey="booking_end_date_after" />
            <BoxedInput type="date" skinType="date" field={f.endDateMax} labelKey="booking_end_date_before" />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.voucherBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="side_menu_section_vouchers" />
          </div>
          <div className="sc-items">
            <BoxedInput type="text" labelKey="common_searchFor_voucherCode" field={f.voucherCode} />
            <BoxedInput type="text" labelKey="common_searchFor_VoucherGroupName" field={f.voucherGroupName} />
            <BoxedInput type="text" labelKey="common_searchFor_voucherGroupId" field={f.voucherGroupIds} />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.errorBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_error" />
          </div>
          <div className="sc-items">
            <BoxedSelect labelKey="bookings_failed" options={booleanOptions} field={f.failed} />
            <BoxedSelect labelKey="bookings_integration_failed" options={booleanOptions} field={f.integrationFailed} />
            <BoxedSelect labelKey="bookings_rrs_failed" options={booleanOptions} field={f.rrsUpdateFailed} />
            <BoxedSelect labelKey="bookings_delayed_status" options={booleanOptions} field={f.delayed} />
          </div>
        </div>
      </form>
    );
  }
}

Filters = reduxForm(
  {
    form: 'bookingsFilters',
    fields: [
      'memberFirstName',
      'memberLastName',
      'memberLogin',
      'voucherCode',
      'voucherGroupName',
      'voucherGroupIds',
      'vehicleRegistrationNumber',
      'bookingId',
      'startDateMin',
      'startDateMax',
      'endDateMin',
      'endDateMax',
      'status',
      'vehicleUsageAtBookingCreation',
      'type',
      'vehicleBrand',
      'vehicleModel',
      'delayed',
      'failed',
      'integrationFailed',
      'rrsUpdateFailed'
    ]
  },
  state => ({
    vehicleBrandsOptions: selectVehicleBrandsOptions(state),
    vehicleModelsOptions: selectVehicleModelsOptions(state),
    vehicleBrandsLoading: vehicleBrandsLoadingSelector(state),
    vehicleModelsLoading: vehicleModelsLoadingSelector(state),
    vehicleBrands: vehicleBrandsSelector(state)
  })
)(Filters);

export default Filters;
