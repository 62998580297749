import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _has from 'lodash/has';
import Close from 'material-ui/svg-icons/navigation/close';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import Modal from 'react-modal';
import moment from 'moment';
import merge from 'merge-deep';
import Checkbox from 'material-ui/Checkbox';
import classnames from 'classnames';

import {
  modalStyles,
  extendShortenModalStyles,
  updateBookingPriceFormStyle,
  AddInvoiceModalStyles,
  startBookingModalStyles
} from '../../../constants/style-constants';

import BackLink from '../../../components/BackLink/BackLink';
import {
  namedCompose,
  newProps,
  addErrorMessage,
  addInvoiceItemsTotal,
  safe,
  getBookingInfo,
  createInitVehiclePlanning,
  toBoolean
} from '../../../utils/utils';
import {
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_TYPE_CAR_SHARING,
  EXTERNAL_SYSTEM_LINKS,
  STATUS_CANCELED
} from '../../../constants/backend-constants';
import BookingInfoV2 from '../../../components/_v2/BookingInfo/BookingInfoV2';
import BookingMember from '../../../components/_v2/BookingMember/BookingMember';
import BookingTripInfo from '../../../components/_v2/BookingTripInfo/BookingTripInfo';
import BookingStatusHistoryV2 from '../../../components/_v2/BookingStatusHistory/BookingStatusHistoryV2';
import BookingVehicleInfo from '../../../components/_v2/BookingVehicleInfo/BookingVehicleInfo';
import VehiclePosition from '../../../components/_v2/VehiclePosition/VehiclePosition';
import BookingBilling from '../../../components/_v2/BookingBilling/BookingBilling';
import BookingTransactionInfo from '../../../components/_v2/BookingTransactionInfo/BookingTransactionInfo';
import CreateInvoiceForm from '../../../components/CreateInvoiceForm/CreateInvoiceForm';
import VehiclePlanningMemberChange from '../VehiclePlanning/components/VehiclePlanningMemberChange';
import { bookingDelayedSelector, bundleSelector, localeSelector } from '../../../selectors/all-selectors';

import {
  bookingViewGetInvoices,
  closeCancelBookingModal,
  closeExtendShortenBookingModal,
  closeFinishBookingModal,
  getBookingTransactions,
  getSingleBooking,
  openCancelBookingModal,
  openExtendShortenBookingModal,
  openFinishBookingModal,
  requestBookingCancel,
  requestBookingExtendShorten,
  requestBookingFinish,
  retryRrsBooking,
  retryUnitRequest,
  settleBooking,
  toggleCreateInvoiceBookingModal,
  emptyDriverLicenseFiles,
  changeAutomaticPaymentStatus,
  retryDmsPayment,
  retryItalianInvoice,
  setBookingListItemStatus,
  openStartBookingModal,
  closeStartBookingModal,
  startBooking,
  clearIntegrationErrors,
  openFixIntegrationErrorsModal,
  fixProplannerError,
  closeFixIntegrationErrorsModal
} from '../../../actions/bookings-actions';
import {
  addInvoiceItem,
  clearItalianInvoiceStatus,
  createInvoice,
  deleteInvoiceItem,
  toggleDepositUsage
} from '../../../actions/invoices-actions';
import { addFlashMessage } from '../../../actions/flashMessage-actions';
import { ALL, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../../constants/generic-constants';

import EkButton from '../../../components/EkButton/EkButton';
import routes, { invoiceEditRules, invoiceRules } from '../../../constants/routes-constants';
import { routeActions } from 'react-router-redux';
import { apiParams } from '../../../constants/api-params-constants';
import ExtendShortenBookingForm from '../../../components/ExtendShortenBookingForm/ExtendShortenBookingForm';
import FinalizeBookingForm from '../../../components/FinalizeBookingForm/FinalizeBookingForm';
import UpdateBookingPriceForm from '../../../components/UpdateBookingPriceForm/UpdateBookingPriceForm';
import { bookingUpdatePrice, bookingUpdatePriceCheck, toggleUpdatePriceModal } from '../../../actions/booking-actions';
import { getImpersonateMember } from '../../../actions/members-actions';
import {
  getBookingVehiclePlanning,
  saveUserTypeForBookingEdit,
  toggleVehiclePlanningEditBooking,
  toggleShortenExtendBooking,
  toggleChangeMember,
  toggleChangeVehicle,
  getDataVehiclePlanning
} from '../../VehiclePlanning/VehiclePlanning.actions';
import { lockVehicle, unlockVehicle } from '../../../actions/vehicles-actions';
import VehiclePlanningChangeVehicle from '../VehiclePlanning/components/VehiclePlanningChangeVehicle';
import { enhanceSearchVehicleData } from '../../../api/data-enhancer';
import { getBookingFindAvailableVehicle } from '../../../actions/booking-find-vehicle-actions';
import memoizeOne from 'memoize-one';
import BookingStartForm from '../../../components/BookingStartForm/BookingStartForm';
import { BOOKING_START_ERROR } from '../../../constants/actionTypes-constants';
import BookingFixIntegrationErrorsForm from '../../../components/BookingFixIntegrationErrorsForm/BookingFixIntegrationErrorsForm';

class BookingViewV2 extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setVariables();
    this.setCallbacks();
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    this.mounted = false;
    dispatch(emptyDriverLicenseFiles());
    dispatch(clearItalianInvoiceStatus());
    dispatch(clearIntegrationErrors());
  }

  derivedStateFromProps(props, init) {
    this.parseNames(props, init);
  }

  setVariables() {
    this.mounted = true;
    this.invoiceRules = merge(invoiceRules, invoiceEditRules);
    this.state = { pendingStartBooking: false, startBookingFailed: false };
  }

  setCallbacks() {
    const getViewEventsHrefMem = memoizeOne(bookingId => {
      return '/#' + routes.bookingEventsHistory.path.replace(':bookingId', bookingId);
    });
    this.getViewEventsHref = () => {
      if (!this.props.handleToggle) {
        return getViewEventsHrefMem(this.props.bookingId);
      }
    };

    this.handleViewEventsOnClick = () => {
      if (this.props.handleToggle) alert('coming soon');
    };
  }

  parseNames(props, init) {
    if (!newProps(this.props, props, init, ['bookingStatus', 'bookingType', 'bookingUsageType', 'urlParams', 'locale'])) return false;

    const { bookingStatus, bookingType, bookingUsageType, urlParams } = props;
    const { formatMessage } = props.intl;

    const encodedParams = encodeURIComponent(JSON.stringify(urlParams || apiParams.default));

    this.backToListURL = `/#${routes.bookings.path.replace(':search', encodedParams)}`;
    this.bookingStatusClass = 'booking_status_' + String(bookingStatus).toLowerCase();
    this.bookingStatus = formatMessage({
      id: 'common_booking_status_' + bookingStatus,
      defaultMessage: formatMessage({ id: 'common_unknown' })
    });
    this.bookingType = formatMessage({
      id:
        bookingType === BOOKING_TYPE_CAR_SHARING
          ? `booking_detail_type_${bookingType}_${bookingUsageType}`
          : `booking_detail_type_${bookingType}`,
      defaultMessage: formatMessage({ id: 'common_unknown' })
    });
  }

  handleChangeAutomaticPaymentRetry() {
    const { automaticPaymentRetry, dispatch, bookingId } = this.props;
    dispatch(changeAutomaticPaymentStatus(bookingId, !automaticPaymentRetry));
  }

  handleChangeDmsPaymentRetry() {
    const { dispatch, bookingId } = this.props;
    dispatch(retryDmsPayment(bookingId));
  }

  handleItalianInvoiceRetry() {
    const { italianInvoiceError, dispatch } = this.props;
    const italianInvoiceNumber = italianInvoiceError.italianInvoiceNumber;
    dispatch(retryItalianInvoice({ italianInvoiceNumber }));
  }

  handleErrorRRS() {
    const { dispatch, bookingId, bookingStatus, rrsFailed, carSharingFailed } = this.props;
    if (rrsFailed && carSharingFailed) {
      dispatch(retryUnitRequest(bookingId, bookingStatus)).then(() => {
        dispatch(retryRrsBooking(bookingId)).then(() => {
          setTimeout(this.updateBookingInfo, 1000);
        });
      });
    } else {
      if (carSharingFailed) this.handleRetryBooking();
      if (rrsFailed) this.handleRetryRrsBooking();
    }
  }

  handleRetryBooking() {
    const { dispatch, bookingId, bookingStatus } = this.props;
    dispatch(retryUnitRequest(bookingId, bookingStatus)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
    });
  }

  handleRetryRrsBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(retryRrsBooking(bookingId)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
    });
  }

  handleSettleBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(settleBooking(bookingId)).then(() => {
      setTimeout(this.updateBookingInfo, 1000);
      setTimeout(() => {
        if (!this.mounted) return false;
        dispatch(getBookingTransactions(bookingId, false));
      }, 2000);
    });
  }

  handleToggleUpdatePriceModal() {
    this.props.dispatch(toggleUpdatePriceModal());
  }

  handleUpdatePrice() {
    this.handleToggleUpdatePriceModal();
    this.props.dispatch(bookingUpdatePrice()).then(this.updateBookingInfo);
  }

  handleUpdatePriceCheck() {
    this.props.dispatch(bookingUpdatePriceCheck());
  }

  handleFinishBooking() {
    const { handleToggle, handleToggleFinish, dispatch } = this.props;
    if (handleToggle) handleToggleFinish();
    else dispatch(openFinishBookingModal());
  }

  handleVehicleChange() {
    const { handleToggle, bookingId, memberId, subCompanySelected } = this.props;

    this.props.dispatch(getBookingVehiclePlanning(bookingId)).then(data => {
      if (subCompanySelected.id === ALL) {
        let params = {
          startDate: moment().toISOString(),
          periodInDays: 3,
          subCompanyId: this.props.vehicleCompanyId
        };
        this.props.dispatch(getDataVehiclePlanning(params));
      } else this.props.dispatch(getDataVehiclePlanning());

      const selectedMember = { id: memberId };
      const values = createInitVehiclePlanning(data, true);
      const newParams = enhanceSearchVehicleData(values, data, selectedMember, true);

      newParams.usages = [data.carSharingInfo.usageType];
      newParams.startParkingId = data.start.parking.id;

      this.props.dispatch(getBookingFindAvailableVehicle(newParams, false));
    });

    this.props.dispatch(toggleChangeVehicle());
  }

  handleMemberChange() {
    this.props.dispatch(toggleChangeMember({ skipConfirm: false }));
  }

  handleAbortFinishBooking() {
    this.props.dispatch(closeFinishBookingModal());
  }

  handleConfirmFinishBooking() {
    const { dispatch, bookingId } = this.props;
    dispatch(closeFinishBookingModal());
    dispatch(requestBookingFinish(bookingId)).then(
      () => {
        dispatch(setBookingListItemStatus(bookingId, BOOKING_STATUS_COMPLETED));
        this.updateBookingInfo();
        this.props.dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      () => {
        this.updateBookingInfo();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleExtendShortenBooking() {
    const { dispatch, handleToggle, booking, vehicle } = this.props;
    if (handleToggle) dispatch(toggleShortenExtendBooking(booking, vehicle, true));
    else dispatch(openExtendShortenBookingModal());
  }

  handleAbortExtendShortenBooking() {
    const { dispatch } = this.props;
    dispatch(closeExtendShortenBookingModal());
  }

  handleConfirmExtendShortenBooking(newEndDateObj) {
    const { dispatch, estimatedEnd, bookingId, registrationNumber } = this.props;
    const offset = moment.parseZone(estimatedEnd).utcOffset();
    const localeOffset = moment.parseZone().utcOffset();

    let endDate = moment
      .utc(newEndDateObj.newEndDate)
      .utcOffset(offset)
      .add(newEndDateObj.hour, 'hours')
      .add(newEndDateObj.min, 'minutes')
      .add(localeOffset - offset, 'minutes')
      .format('YYYY-MM-DDTHH:mmZ');

    dispatch(closeExtendShortenBookingModal());
    dispatch(requestBookingExtendShorten(bookingId, registrationNumber, endDate)).then(this.updateBookingInfo);
  }

  handleEditBooking() {
    const { dispatch, bookingId, handleToggle, openedEditBookingModal } = this.props;

    if (handleToggle) {
      if (!openedEditBookingModal) {
        dispatch(getBookingVehiclePlanning(bookingId)).then(data => dispatch(saveUserTypeForBookingEdit(data)));
      }
      dispatch(toggleVehiclePlanningEditBooking());
    } else dispatch(routeActions.push(routes.editBooking.path.replace(':bookingId', bookingId)));
  }

  handleCancelBooking() {
    const { dispatch, handleToggle, handleToggleDialog } = this.props;
    if (handleToggle) handleToggleDialog();
    else dispatch(openCancelBookingModal());
  }

  handleOpenStartBooking() {
    const { dispatch, handleToggle, handleToggleDialog } = this.props;
    if (handleToggle) handleToggleDialog();
    else dispatch(openStartBookingModal());
  }

  handleOpenFixIntegrationError() {
    const { dispatch } = this.props;
    dispatch(openFixIntegrationErrorsModal());
  }

  handleLockVehicle() {
    const { dispatch, vehicle } = this.props;
    dispatch(lockVehicle(vehicle.id)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_lock_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    });
  }

  handleUnlockVehicle() {
    const { dispatch, vehicle } = this.props;
    dispatch(unlockVehicle(vehicle.id)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_unlock_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    });
  }

  handleAbortCancelBooking() {
    const { dispatch } = this.props;
    dispatch(closeCancelBookingModal());
  }

  handleAbortStartBooking() {
    const { dispatch } = this.props;
    dispatch(closeStartBookingModal());
  }

  handleInvoiceCreation() {
    const { dispatch, bookingId } = this.props;
    this.handleInvoiceModal();
    dispatch(createInvoice(bookingId));
  }

  handleInvoiceModal() {
    const { dispatch } = this.props;
    dispatch(toggleCreateInvoiceBookingModal());
  }

  handleInvoiceAddItem(item) {
    const { dispatch } = this.props;
    dispatch(addInvoiceItem(item));
  }

  handleInvoiceDeleteItem(item) {
    const { dispatch } = this.props;
    dispatch(deleteInvoiceItem(item));
  }

  showBookingError() {
    const { dispatch } = this.props;
    dispatch(
      addFlashMessage({
        contentKey: 'error_update_booking_info',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  updateBookingInfo() {
    const { dispatch, bookingId } = this.props;
    if (!this.mounted) return false;
    dispatch(getSingleBooking(bookingId, false)).then(null, this.showBookingError);
    dispatch(bookingViewGetInvoices(bookingId)).then(null, this.showBookingError);
  }

  handleConfirmStartBooking() {
    const { dispatch, bookingId } = this.props;
    this.setState({ pendingStartBooking: true });
    dispatch(startBooking()).then(
      () => {
        dispatch(addFlashMessage({ contentKey: 'booking.start.success', type: FLASH_MESSAGE_TYPE_SUCCESS }));
        dispatch(getSingleBooking(bookingId, false));
        dispatch(setBookingListItemStatus(bookingId, BOOKING_STATUS_IN_PROGRESS));
        dispatch(closeStartBookingModal());
        this.setState({ pendingStartBooking: false });
      },
      error => {
        this.setState({ pendingStartBooking: false, startBookingFailed: true });
        dispatch({ type: BOOKING_START_ERROR, error });
        return dispatch(addErrorMessage({ error }));
      }
    );
  }

  pendingStartBooking() {
    return this.state.pendingStartBooking;
  }

  startBookingFailed() {
    return this.state.startBookingFailed;
  }

  handleConfirmCancelBooking() {
    const { dispatch, bookingId } = this.props;

    dispatch(closeCancelBookingModal());
    dispatch(requestBookingCancel(bookingId)).then(
      () => {
        dispatch(setBookingListItemStatus(bookingId, STATUS_CANCELED));
        this.updateBookingInfo();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      () => {
        this.updateBookingInfo();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleImpersonate(openInNewTab) {
    const { dispatch, memberId, companyId } = this.props;
    dispatch(getImpersonateMember(memberId, companyId, openInNewTab));
  }

  toggleDepositUsage() {
    const { dispatch } = this.props;
    dispatch(toggleDepositUsage());
  }

  displayInvoiceCreationModal() {
    const { invoicesItems, totalPrices, useBookingDamageDeposit, intl } = this.props;
    const invoiceItemsWithTotal = invoicesItems.length ? addInvoiceItemsTotal(invoicesItems, totalPrices) : [];
    const currencySymbol = intl.formatMessage({ id: `unit_${this.props.companyCurrency}` });

    if (Boolean(this.props.openedInvoiceCreationModal)) {
      return (
        <Modal isOpen style={AddInvoiceModalStyles}>
          <Close
            onClick={this.handleInvoiceModal}
            style={{ float: 'right', cursor: 'pointer' }}
            color={'rgba(0, 0, 0, .54)'}
            hoverColor={'rgba(0, 0, 0, .84)'}
          />
          <h4>{intl.messages['create_invoice_button']}</h4>

          <CreateInvoiceForm onCallback={this.handleInvoiceAddItem} currency={this.props.companyCurrency} />

          {invoicesItems.length > 0 && (
            <div className="createInvoiceForm_container">
              <ul>
                <li className="header-invoice">
                  <span className="desc" children={intl.messages['invoices_tableView_label_description']} />
                  <span
                    children={
                      intl.messages['invoices_tableView_label_quantity'] +
                      ' * ' +
                      intl.formatMessage({ id: 'createInvoiceForm_priceWithVAT' }, { currency: currencySymbol })
                    }
                    className="quantity-unit"
                  />
                  <span
                    children={intl.formatMessage({ id: 'invoice_amount_with_currency' }, { currency: currencySymbol })}
                    className="price"
                  />
                </li>
                {invoicesItems.map((item, index) => {
                  return (
                    <li key={'dim' + index}>
                      <span children={item.description} className="desc" />
                      <span children={item.quantity + ' * ' + item.pricePerUnitWithVat} className="quantity-unit" />
                      <span children={item.itemPriceWithVat} className="price" />
                      <span className="delete-item">
                        <DeleteIcon
                          color={'rgba(0, 0, 0, .54)'}
                          style={{ cursor: 'pointer' }}
                          hoverColor={'rgba(0, 0, 0, .84)'}
                          onClick={() => {
                            this.handleInvoiceDeleteItem(item);
                          }}
                        />
                      </span>
                    </li>
                  );
                })}
                {invoiceItemsWithTotal.map(item => {
                  if (_get(item, 'translateKey') === 'TOTAL') {
                    return (
                      <li key="globalPrice" className="footer-invoice">
                        <span className="desc" children={intl.messages['common_price_with_vat']} />
                        <span className="amount" children={item.itemPriceWithVat} />
                        <span className="delete-item" />
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          )}

          {invoicesItems.length > 0 && (
            <div>
              <hr />
              <Checkbox
                label={<FormattedMessage id="invoice_booking_deposit_usage" />}
                id="useBookingDamageDeposit"
                checked={useBookingDamageDeposit}
                onCheck={() => this.toggleDepositUsage()}
                customClass="addBookingConfirmationForm_option"
              />
              <EkButton
                type="button"
                formRowItemKey="createInvoiceForm_submitBtn"
                customClass="createInvoiceForm_actionsButton"
                onAction={this.handleInvoiceCreation}
              >
                <FormattedMessage id="invoice_create_generate_button" />
              </EkButton>
            </div>
          )}
          <EkButton type="button" skinType="reverse" customClass="createInvoiceForm_actionsButton" onAction={this.handleInvoiceModal}>
            <FormattedMessage id="common_cancel" />
          </EkButton>
        </Modal>
      );
    }
  }

  displayCancelModal() {
    if (Boolean(this.props.openedCancelModalBooking)) {
      return (
        <Modal isOpen style={modalStyles}>
          <p>
            <FormattedMessage id="booking_detail_cancelBooking_text" />
          </p>
          <EkButton
            type="button"
            skinType="reverse"
            customClass="bookingView_cancelBooking_abort_button"
            onAction={this.handleAbortCancelBooking}
          >
            <FormattedMessage id="common_no" />
          </EkButton>
          <EkButton type="button" customClass="bookingView_cancelBooking_confirm_button" onAction={this.handleConfirmCancelBooking}>
            <FormattedMessage id="common_yes" />
          </EkButton>
        </Modal>
      );
    }
  }

  startBookingFailedMsg() {
    const { error: errorObj } = this.props.startError;
    if (_has(errorObj, 'errorCode')) {
      return (
        <div className="error">
          <FormattedMessage id={_get(errorObj, 'errorCode')} />
        </div>
      );
    }
    if (_has(errorObj, 'developerMessage')) {
      return <div className="error">{_get(errorObj, 'developerMessage')}</div>;
    }
  }
  handleCloseFormIntegrationErrors() {
    const { dispatch } = this.props;
    dispatch(closeFixIntegrationErrorsModal());
  }

  handleFixProplannerError() {
    const { dispatch, bookingId } = this.props;
    dispatch(fixProplannerError(bookingId, EXTERNAL_SYSTEM_LINKS.PROPLANNER)).then(() => setTimeout(this.updateBookingInfo, 1000));
  }

  displayFixIntegrationErrorsModal() {
    if (Boolean(this.props.openedFixIntegrationErrorsModalBooking))
      return (
        <Modal isOpen style={extendShortenModalStyles}>
          <h4>
            <Close
              onClick={this.handleCloseFormIntegrationErrors}
              style={{ float: 'right', cursor: 'pointer' }}
              color={'rgba(0, 0, 0, .54)'}
              hoverColor={'rgba(0, 0, 0, .84)'}
            />
            <FormattedMessage id="proplanner-title-fix-form" />
          </h4>
          <BookingFixIntegrationErrorsForm onCallback={this.handleFixProplannerError} onAbort={this.handleCloseFormIntegrationErrors} />
        </Modal>
      );
  }

  displayStartModal() {
    if (toBoolean(this.props.openedStartModalBooking)) {
      return (
        <Modal isOpen style={startBookingModalStyles}>
          <BookingStartForm
            cancel={this.handleAbortStartBooking}
            confirm={this.handleConfirmStartBooking}
            errorReq={this.startBookingFailed()}
          />
          {this.startBookingFailedMsg()}
        </Modal>
      );
    }
  }

  displayVehicleChangeModal() {
    if (Boolean(this.props.openedChangeVehicleModal)) {
      return <VehiclePlanningChangeVehicle withList={this.props.withList} />;
    }
  }

  displayExtendShortenModal() {
    if (Boolean(this.props.openedExtendShortenModalBooking)) {
      return (
        <Modal isOpen style={extendShortenModalStyles}>
          <ExtendShortenBookingForm
            currentEndDate={this.props.estimatedEnd}
            onCallback={this.handleConfirmExtendShortenBooking}
            onAbort={this.handleAbortExtendShortenBooking}
          />
        </Modal>
      );
    }
  }

  displayFinishModal() {
    if (Boolean(this.props.openedFinishModalBooking)) {
      return (
        <Modal isOpen style={modalStyles}>
          <FinalizeBookingForm onCallback={this.handleConfirmFinishBooking} onAbort={this.handleAbortFinishBooking} />
        </Modal>
      );
    }
  }

  displayUpdatePriceModal() {
    if (Boolean(this.props.openedUpdatePriceModal)) {
      return (
        <Modal isOpen style={updateBookingPriceFormStyle}>
          <UpdateBookingPriceForm
            currency={this.props.bookingCurrency}
            onCallback={this.handleUpdatePrice}
            onCallbackCheck={this.handleUpdatePriceCheck}
            onAbort={this.handleToggleUpdatePriceModal}
          />
        </Modal>
      );
    }
  }

  displayMemberChange() {
    if (this.props.openedMemberChangeModal) {
      return <VehiclePlanningMemberChange />;
    }
  }

  render() {
    return (
      <div className="mainContainer_content">
        <div className="pageContainer">
          {!this.props.handleToggle && <BackLink link={this.backToListURL} labelKey="back_link_bookings_list" />}
          <div className={classnames('bookingViewV2', { tile: this.props.handleToggle })}>
            {this.displayCancelModal()}
            {this.displayStartModal()}
            {this.displayExtendShortenModal()}
            {this.displayFinishModal()}
            {this.displayInvoiceCreationModal()}
            {this.displayUpdatePriceModal()}
            {this.displayMemberChange()}
            {this.displayVehicleChangeModal()}
            {this.displayFixIntegrationErrorsModal()}

            <div className="bookingViewV2_body">
              <BookingInfoV2
                companyName={this.props.companyName}
                companyId={this.props.companyId}
                bookingId={this.props.bookingId}
                bookingStatus={this.props.bookingStatus}
                bookingUsage={this.props.bookingUsage}
                bookingUsageType={this.props.bookingUsageType}
                memberId={this.props.memberId}
                bookingMemberType={this.props.bookingMemberType}
                price={this.props.totalPriceIncludingTaxes || this.props.estimatedPriceForDuration}
                currency={this.props.bookingCurrency}
                freeOfCharges={this.props.freeOfCharges}
                score={this.props.score}
                delayedBooking={this.props.delayedBooking}
                cancelBooking={this.handleCancelBooking}
                extendBooking={this.handleExtendShortenBooking}
                finishBooking={this.handleFinishBooking}
                editBooking={this.handleEditBooking}
                openStartBooking={this.handleOpenStartBooking}
                updatePrice={this.handleToggleUpdatePriceModal}
                integrationErrors={this.props.integrationErrors}
                openFixIntegrationError={this.handleOpenFixIntegrationError}
              />
              <BookingMember
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                memberId={this.props.memberId}
                companyId={this.props.companyId}
                memberStatus={this.props.memberStatus}
                drivingLicenceFiles={this.props.bookingFiles}
                drivingLicenceMetaData={this.props.drivingLicenceMetaData}
                drivingLicenceStatus={this.props.drivingLicenceStatus}
                impersonate={this.handleImpersonate}
                changeMember={this.handleMemberChange}
                bookingStatus={this.props.bookingStatus}
              />
              <BookingTripInfo
                estimatedStart={this.props.estimatedStart}
                effectiveStart={this.props.effectiveStart}
                bookingStartParking={this.props.bookingStartParking}
                bookingStartSite={this.props.bookingStartSite}
                bookingEndParking={this.props.bookingEndParking}
                bookingEndSite={this.props.bookingEndSite}
                estimatedEnd={this.props.estimatedEnd}
                effectiveEnd={this.props.effectiveEnd}
                bookingStartMileage={this.props.startMileage}
                bookingEndMileage={this.props.endMileage}
                distanceTravelled={this.props.distanceTravelled}
                freeKmPerDay={this.props.freeKmPerDay}
                startFuel={this.props.startFuel}
                endFuel={this.props.endFuel}
                brandId={this.props.brandId}
                bookingStatus={this.props.bookingStatus}
                statusHistory={this.props.statusHistory}
              />
              <BookingStatusHistoryV2
                statusHistory={this.props.statusHistory}
                estimatedDates={{ start: this.props.estimatedStart, end: this.props.estimatedEnd }}
                effectiveDates={{ start: this.props.effectiveStart, end: this.props.effectiveEnd }}
                creationDate={this.props.creationDate}
                locale={this.props.locale}
                bookingStatus={this.props.bookingStatus}
                viewEventsHref={this.getViewEventsHref()}
                viewEventOnClick={this.handleViewEventsOnClick}
              />
              <BookingVehicleInfo
                vehicle={this.props.vehicle}
                vehicleCompanyId={this.props.vehicleCompanyId}
                vehicleCompanyName={this.props.vehicleCompanyName}
                vehicleCategoryType={this.props.vehicleCategoryType}
                bookingStatus={this.props.bookingStatus}
                changeVehicle={this.handleVehicleChange}
                lockVehicle={this.handleLockVehicle}
                unlockVehicle={this.handleUnlockVehicle}
              />
              <VehiclePosition
                position={this.props.lastPositionKnown}
                vehicleId={this.props.vehicleId}
                companyId={this.props.vehicleCompanyId}
                vehicle={this.props.vehicle}
                vehicleFuelType={this.props.vehicle.fuelType}
              />
              <BookingBilling
                statusPayment={this.props.statusPayment}
                bookingUsageType={this.props.bookingUsageType}
                invoiceNumber={this.props.invoiceNumber}
                currency={this.props.bookingCurrency}
                estimatedPriceForDuration={this.props.estimatedPriceForDuration}
                totalPriceIncludingTaxes={this.props.totalPriceIncludingTaxes}
                freeKmPerDay={this.props.freeKmPerDay}
                voucherDiscountAmount={this.props.voucherDiscountAmount}
                bookingInvoices={this.props.bookingInvoices}
                addInvoice={this.handleInvoiceModal}
                updatePrice={this.handleToggleUpdatePriceModal}
                paymentResettlingAllowed={this.props.paymentResettlingAllowed}
                handleSettleBooking={this.handleSettleBooking}
                dmsInvoiceErrors={this.props.dmsInvoiceErrors}
                dmsRetry={this.handleChangeDmsPaymentRetry}
                bookingStatus={this.props.bookingStatus}
                italianInvoiceError={this.props.italianInvoiceError}
                italianInvoices={this.props.italianInvoices}
                italianInvoiceRetry={this.handleItalianInvoiceRetry}
                errorRrs={this.handleErrorRRS}
                rrsFailed={this.props.rrsFailed}
                carSharingFailed={this.props.carSharingFailed}
              />
              <BookingTransactionInfo
                paymentAutoRetry={this.handleChangeAutomaticPaymentRetry}
                transactions={this.props.transactions}
                locale={this.props.locale}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default namedCompose(
  injectIntl,
  connect(state => {
    const bookingDetail = _get(state.bookings, 'bookingDetail');
    const startError = _get(state.bookings, 'startError');
    const openedMemberChangeModal = _get(state.vehiclePlanning, 'openedMemberChangeModal');
    const openedChangeVehicleModal = _get(state.vehiclePlanning, 'openedChangeVehicleModal');
    const subCompanySelected = _get(state.subCompanies, 'subCompanySelected');
    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    const rideSharingInfo = _get(bookingDetail, 'rideSharingInfo');
    const rrsSharingInfo = _get(bookingDetail, 'rrsSharingInfo');
    const bookingMember = _get(bookingDetail, 'member');
    const bookingStart = _get(bookingDetail, 'start');
    const bookingEnd = _get(bookingDetail, 'end');
    const score = _get(bookingDetail, 'score') || {};
    const bookingStartParkingObject = _get(bookingStart, 'parking');
    const bookingEndParkingObject = _get(bookingEnd, 'parking');
    const memberCompany = _get(bookingMember, 'company');
    const rideSharingOwner = _get(rideSharingInfo, 'owner');
    const carSharingCost = _get(carSharingInfo, 'cost');
    const carSharingPickup = _get(carSharingInfo, 'pickUp');
    const startVehicleStatusData = _get(carSharingInfo, 'startVehicleStatusData');
    const endVehicleStatusData = _get(carSharingInfo, 'endVehicleStatusData');
    const vehicleObject = _get(bookingDetail, 'vehicle');
    const vehicleModelObject = _get(vehicleObject, 'version.model');
    const detailMember = { drivingLicence: _get(bookingDetail, 'drivingLicenceMetaData') };
    const drivingLicenceStatus = _get(detailMember, 'drivingLicence.status');
    const lastPositionKnown = _get(vehicleObject, 'lastPosition');
    const creationDate = _get(bookingDetail, 'creationDate');

    const effectiveStart = getBookingInfo(bookingDetail, 'effectiveStartDate');
    const effectiveEnd = getBookingInfo(bookingDetail, 'effectiveEndDate');
    const delayedBooking = bookingDelayedSelector(state);

    const integrationErrors = _get(state.bookings, 'bookingExternalErrors');

    return {
      locale: localeSelector(state),
      bundle: bundleSelector(state),
      apiToken: _get(state.api, 'token'),
      transactions: _get(state.bookings, 'bookingTransactions'),
      bookingInvoices: _get(state.bookings, 'bookingInvoices'),
      bookingFiles: _get(state.bookings, 'bookingFiles'),
      urlParams: _get(state.bookings, 'urlParams'),
      openedInvoiceCreationModal: _get(state.bookings, 'openedInvoiceCreationModal'),
      openedCancelModalBooking: _get(state.bookings, 'openedCancelModalBooking'),
      openedStartModalBooking: _get(state.bookings, 'openedStartModalBooking'),
      openedExtendShortenModalBooking: _get(state.bookings, 'openedExtendShortenModalBooking'),
      openedFinishModalBooking: _get(state.bookings, 'openedFinishModalBooking'),
      openedUpdatePriceModal: _get(state.bookings, 'openedUpdatePriceModal'),
      openedFixIntegrationErrorsModalBooking: _get(state.bookings, 'openedFixIntegrationErrorsModalBooking'),
      bookingMemberType: _get(bookingDetail, 'memberType.name'),
      bookingStatus: _get(bookingDetail, 'status'),
      bookingType: _get(bookingDetail, 'type'),
      bookingCurrency: _get(bookingDetail, 'currency'),
      bookingId: _get(bookingDetail, 'id'),
      bookingUsage: _get(bookingDetail, 'vehicleUsageAtBookingCreation'),
      reservedSeats: _get(bookingDetail, 'reservedSeats'),
      bookingCustomValues: _get(bookingDetail, 'bookingCustomValues'),
      comment: _get(bookingDetail, 'comment'),
      statusHistory: _get(bookingDetail, 'allStatuses'),
      paymentResettlingAllowed: _get(bookingDetail, 'paymentResettlingAllowed'),
      drivingLicenceMetaData: _get(bookingDetail, 'drivingLicenceMetaData'),
      bookingUsageType: _get(carSharingInfo, 'usageType'),
      openToRideSharing: _get(carSharingInfo, 'openToRideSharing'),
      statusPayment: _get(carSharingInfo, 'statusPayment'),
      freeOfCharges: _get(carSharingInfo, 'freeOfCharges'),
      invoiceNumber: _get(carSharingInfo, 'invoiceNumber'),
      rrsFailed: _get(rrsSharingInfo, 'rrsUpdateFailed'),
      carSharingFailed: _get(carSharingInfo, 'failed'),
      automaticPaymentRetry: _get(carSharingInfo, 'automaticPaymentRetry'),
      firstName: _get(bookingMember, 'firstName'),
      lastName: _get(bookingMember, 'lastName'),
      memberStatus: _get(bookingMember, 'status'),
      memberId: _get(bookingMember, 'id'),
      companyCurrency: _get(memberCompany, 'currency'),
      companyName: _get(memberCompany, 'name'),
      companyId: _get(memberCompany, 'id'),
      ownerFirstName: _get(rideSharingOwner, 'firstname'),
      ownerLastName: _get(rideSharingOwner, 'lastname'),
      ownerBookingId: _get(rideSharingInfo, 'bookingId'),
      estimatedPriceForDuration: _get(carSharingCost, 'estimatedPriceForDuration'),
      totalPriceIncludingTaxes: _get(carSharingCost, 'totalPriceIncludingTaxes'),
      freeKmPerDay: _get(carSharingCost, 'freeKmPerDay'),
      voucherDiscountAmount: _get(carSharingCost, 'voucherDiscountAmount'),
      estimatedStart: _get(bookingStart, 'date'),
      bookingStartAddress: _get(bookingStart, 'address.formattedAddress'),
      estimatedEnd: _get(bookingEnd, 'date'),
      bookingEndAddress: _get(bookingEnd, 'address.formattedAddress'),
      bookingStartParking: bookingStartParkingObject,
      bookingStartSite: _get(bookingStartParkingObject, 'site'),
      bookingEndParking: bookingEndParkingObject,
      bookingEndSite: _get(bookingEndParkingObject, 'site.name'),
      effectiveStart: effectiveStart,
      effectiveEnd: effectiveEnd,
      creationDate,
      pickupAdress: _get(carSharingPickup, 'address.formattedAddress'),
      pickupDate: _get(carSharingPickup, 'date'),
      vehicleModel: _get(vehicleModelObject, 'name'),
      vehicleBrand: _get(vehicleModelObject, 'brand.name'),
      vehicleId: _get(vehicleObject, 'id'),
      vehicleSeats: _get(vehicleObject, 'seats'),
      vehicle: vehicleObject,
      vehicleCompanyId: _get(vehicleObject, 'lastPosition.parking.site.subCompanyId'),
      vehicleCategoryType: _get(vehicleObject, 'category.type'),
      registrationNumber: _get(vehicleObject, 'registrationNumber'),
      vehicleCompanyName: _get(state.bookings, 'vehicleCompanyName'),
      startMileage: _get(startVehicleStatusData, 'mileage'),
      startFuel: _get(startVehicleStatusData, 'fuel'),
      endMileage: _get(endVehicleStatusData, 'mileage'),
      endFuel: _get(endVehicleStatusData, 'fuel'),
      invoicesItems: _get(state.invoices, 'invoiceItems'),
      useBookingDamageDeposit: _get(state.invoices, 'useBookingDamageDeposit'),
      totalPrices: _get(state.invoices, 'totalPrices'),
      italianInvoiceError: _get(state.invoices, 'italianInvoiceError'),
      italianInvoices: _get(state.invoices, 'italianInvoices'),
      dmsInvoiceErrors: safe(() => state.invoices.dmsInvoiceErrors),
      drivingLicenceStatus,
      detailMember,
      delayedBooking,
      lastPositionKnown,
      brandId: _get(memberCompany, 'brand.id'),
      distanceTravelled: _get(carSharingInfo, 'distanceTravelled'),
      score,
      openedMemberChangeModal,
      openedChangeVehicleModal,
      subCompanySelected,
      startError,
      integrationErrors
    };
  })
)(BookingViewV2);
