import {
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE,
  VEHICLE_USAGE_TYPE_DELIVERY,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS
} from './backend-constants';

export const FLASH_MESSAGE_TYPE_INFO = 'FLASH_MESSAGE_TYPE_INFO';
export const FLASH_MESSAGE_TYPE_WARNING = 'FLASH_MESSAGE_TYPE_WARNING';
export const FLASH_MESSAGE_TYPE_ERROR = 'FLASH_MESSAGE_TYPE_ERROR';
export const FLASH_MESSAGE_TYPE_SUCCESS = 'FLASH_MESSAGE_TYPE_SUCCESS';

export const SORT_DESC = 'DESC';
export const SORT_ASC = 'ASC';

export const INVOICE_CREATE_TITLE = 'invoice_create_find_member_title';
export const INVOICE_CREATE_FIND_MEMBER_TITLE = 'invoice_create_find_member_subtitle';
export const INVOICE_DETAILS_TITLE = 'invoice_details_subtitle';
export const INVOICE_DETAILS_TOTAL = 'TOTAL';

export const OS_WINDOWS = 'WindowsOs';
export const OS_MAC = 'MacOs';
export const OS_UNIX = 'UnixOs';
export const OS_LINUX = 'LinuxOs';
export const OS_UNKNOWN = 'othersOs';

export const ERROR = 'ERROR';
export const UPLOADING = 'UPLOADING';
export const SUCCESS = 'SUCCESS';
export const FILE = 'FILE';
export const FRONT = 'FRONT';
export const BACK = 'BACK';
export const SELFIE = 'SELFIE';

export const BOOKING = 'BOOKING';
export const MEMBER_NAME = 'MEMBER_NAME';
export const MEMBER_EMAIL = 'MEMBER_EMAIL';
export const VEHICLE = 'VEHICLE';
export const COMPANY = 'COMPANY';

export const PASSWORD_FIELD_NAME = 'password';
export const PASSWORD_CONFIRM_FIELD_NAME = 'confirmationPassword';

export const COMPANY_TYPE_SUPER = 'SUPER';
export const COMPANY_TYPE_SUB = 'SUB';

export const ISO_DATE_FLAT = 'YYYY-MM-DD';
export const ISO_DATE_LOCAL = 'YYYY-MM-DDTHH:mm:ssZ'; // do not delete
export const ISO_DATE_LOCAL_SSS = 'YYYY-MM-DDTHH:mm:ss.SSS'; // do not delete
export const ISO_DATE_FULL = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'; // will be used shortly
export const SEARCH_INVOICES_DATE_FORMAT = 'YYYY-MM-DDTHH:mmZ';

export const DMY_FORMAT = 'DD-MM-YYYY';

export const AUTH_TOKEN_HEADER = 'x-auth-token';

export const invoicePreviewDefault = {
  blob: null,
  base64: null
};

export const VEHICLES_GET_REPORTS_ID = 'VEHICLES_GET_REPORTS_ID';

export const NEW_TAB_ICON_PATH = '/img/external-link-color.svg';

export const ALL = 'ALL';

export const COMPANY_ALL = Object.freeze({
  id: ALL,
  name: ALL
});

export const STORAGE_KEY = {
  PAYBACK_DATA: 'PAYBACK_DATA',
  PAYBACK_SAVE: 'PAYBACK_ORIGIN',
  REDIRECT_LOCATION: 'REDIRECT_LOCATION',
  REDIRECT_NOTIFIED: 'REDIRECT_NOTIFIED',
  NEW_UI: 'NEW_UI',
  MOBILE_VIEW: 'MOBILE_VIEW',
  API_URL: 'API_URL',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  DASHBOARD_TIME: 'DASHBOARD_TIME',
  BANNER_DISPLAY: 'BANNER_DISPLAY',
  BANNER_MSG: 'BANNER_MSG',
  VP_V1: 'VP_V1',
  QUICK_SIGHT_TEST_EMAIL: 'QS_TEST_EMAIL',
  HEADER_COMPANY_ID: 'HEADER_ID',
  HEADER_SUB_COMPANY_ID: 'HEADER_SID'
};

export const BANK_ACCOUNT_DETAILS_FIELD = 'bankAccountDetails';

export const COMMISSION_FORM_FIELDS = [
  'commissionVatRate',
  'commissionRate',
  'invoicingCountry',
  'commissionRateFromDate',
  'commissionCurrency'
];

export const BANK_TRANSFER_FORM_FIELDS = ['bankoutLabel', 'timeBeforePayment', 'bankoutFrequency', 'bankoutExecutionDay'];
export const ADD_BANK_DETAILS_FORM_FIELDS = [BANK_ACCOUNT_DETAILS_FIELD, 'bankCountry', 'bankCurrency'];
export const ADD_CONNECTED_ACCOUNT_FORM_FIELDS = ['bankCountry', 'bankCurrency', 'email'];

export const SSO_FORMM_FIELDS = [
  'identityProvider',
  'ssoDomains',
  'allowRegistrationWithoutSso',
  'emailAutomaticallyVerified',
  'profileAutomaticallyApproved'
];

export const VEHICLE_REPORT_ADDITIONAL_FIELDS = [
  'nameForElectricVehicle',
  'nameForHybridVehicle',
  'nameForThermalVehicle',
  'startBooking',
  'endBooking'
];

export const MATERIAL_LOCALE_MAP = {
  pt_BR: 'pt-br'
};

export const SAFE_LOCALE_MAP = {
  pt_BR: 'pt'
};

export const quickSightLocaleMap = {
  en: 'en-US',
  da: 'da-DK',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  nl: 'nl-NL',
  pt: 'pt-BR',
  pt_BR: 'pt-BR',
  sv: 'sv-SE'
};

export const vpBusinessUsageGroup = [VEHICLE_USAGE_TYPE_IMMEDIATE, VEHICLE_USAGE_TYPE_PLANNED];
export const vpDealerUsageGroup = [
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE,
  VEHICLE_USAGE_TYPE_DELIVERY
];

export const smartcardsUserSearchTypes = {
  EMAIL: 'EMAIL',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME'
};

export const fileUploadExtTypes = {
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
  pdf: 'pdf'
};

export const NUMBER_FORMAT = {
  E164: 0,
  INTERNATIONAL: 1,
  NATIONAL: 2,
  RFC3966: 3
};

export const MEMBER_SOURCE_NAME = 'NAME';
export const MEMBER_SOURCE_EMAIL = 'EMAIL';
export const PRODUCT_NAME = 'Car sharing';
export const SITE_ERROR = 'Failed to load site configuration';
export const LOADING = 'LOADING';

export const keysCustomFieldTypes = {
  BOOLEAN: 'customFieldType_BOOLEAN',
  FILE: 'customFieldType_FILE',
  NUMERIC: 'customFieldType_NUMERIC',
  PHONE_NUMBER: 'customFieldType_PHONE_NUMBER',
  TEXT: 'customFieldType_TEXT'
};

export const applicationToFoUrlBrandMap = {
  GLIDE_WEB: 'glide',
  RRENT_WEB: 'rrent',
  RENAULT_WEB: 'renault',
  SHARE_MOBILITY_WEB: 'share-mobility',
  ENGIE_WEB: 'engie',
  SNCF_WEB: 'sncf',
  AGILAUTO_WEB: 'agilauto'
};

export const themeToLocalBoUrlBrandMap = {
  glide: 'glide',
  renaultB2B: 'renault',
  nissan: 'nissan',
  'share-mobility': 'share-mobility',
  engie: 'engie',
  sncf: 'sncf',
  ego: 'ego',
  agilauto: 'agilauto'
};

export const themeToLocalFoUrlBrandMap = {
  glide: 'glide',
  renaultB2B: 'rrent',
  nissan: 'nissan',
  'share-mobility': 'share-mobility',
  engie: 'engie',
  sncf: 'sncf',
  ego: 'nissan',
  agilauto: 'agilauto'
};

export const brandIdToThemeMap = {
  dev: {
    '03c046bc-b67c-40aa-9ee3-7c4b7aece778': 'engie',
    '7efab73a-d515-4dac-929c-501fdc1d391d': 'glide',
    '530d28d7-bf53-4660-90be-0eaefec9f974': 'renaultB2B',
    '7efab73a-d515-4dac-929c-501fdc1d391e': 'share-mobility',
    '6efab73a-d515-4dac-929c-501fdc1d391d': 'sncf',
    'aefab73a-d515-4dac-929c-501fdc1d391e': 'agilauto'
  },
  valid: {
    'd2cb1e36-4038-41e2-ad56-66b735f7b621': 'ego',
    'c084c710-09c1-4078-a6b8-b560364727c3': 'engie',
    'c42e83d8-aa24-11ea-bb37-0242ac130002': 'glide',
    'be82aeef-aa02-4664-a415-5e252fda16cd': 'renaultB2B',
    'be82aeef-aa02-4664-a415-5e252fda16bc': 'share-mobility',
    '7cbae1b7-4714-46ad-9fee-f85e0086fe11': 'sncf',
    '6cbae1b7-4714-46ad-9fee-f85e0086fe1a': 'nissan',
    '5cbae1b7-4714-46ad-9fee-f85e0086fe12': 'agilauto'
  },
  staging: {
    '020c3b5e-b6f3-4740-bb5d-d829fc8e2590': 'ego',
    '105b6dca-29ee-4651-803c-684a8235f904': 'engie',
    'ae072d16-a5a5-47dd-a573-981c749df56b': 'glide',
    'e269a2e9-0aac-481e-afd0-fa59f72f55b0': 'renaultB2B',
    '520d7064-1e3c-4481-8bfe-96901a3c27ab': 'share-mobility',
    '1a46f38d-02f9-4e30-affb-be1ed589c132': 'nissan',
    '0263ab34-e906-4b87-8ef9-9f9a9da0a3f1': 'agilauto'
  },
  prod: {
    '020c3b5e-b6f3-4740-bb5d-d829fc8e2590': 'ego',
    '105b6dca-29ee-4651-803c-684a8235f904': 'engie',
    'ae072d16-a5a5-47dd-a573-981c749df56b': 'glide',
    'e269a2e9-0aac-481e-afd0-fa59f72f55b0': 'renaultB2B',
    '520d7064-1e3c-4481-8bfe-96901a3c27ab': 'share-mobility',
    'd042a38c-4031-4a6e-827d-6094b40cfaa3': 'sncf',
    '5cc24687-ed94-4bf4-97a9-1568a98b8f48': 'agilauto'
  }
};

export const localEnvs = ['dev', 'valid', 'staging'];
export const validEnvs = ['dev', 'valid', 'staging', 'prod'];

export const FLASH_MESSAGE_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  BUG: 'bug',
  DISABLED: 'disabled'
};

export const FLASH_MESSAGE_MAP = {
  FLASH_MESSAGE_TYPE_ERROR: FLASH_MESSAGE_TYPE.ERROR,
  FLASH_MESSAGE_TYPE_SUCCESS: FLASH_MESSAGE_TYPE.SUCCESS,
  FLASH_MESSAGE_TYPE_WARNING: FLASH_MESSAGE_TYPE.WARNING,
  FLASH_MESSAGE_TYPE_INFO: FLASH_MESSAGE_TYPE.INFO
};

export const contractInitialValues = {
  booking: {
    memberPreBooking: false,
    customFields: true,
    damageReport: true,
    damageWithPhoto: true,
    damageExtraPhoto: true,
    dekra: true,
    proplanner: false,
    smartcards: true
  },
  member: {
    customFields: true,
    managerEmail: true,
    expediteReview: true,
    uploadList: true,
    type: true,
    sso: true
  },
  vehicle: {
    usageImmediate: true,
    usagePlanned: true,
    usageRV: true,
    testDrive: true,
    publicService: true,
    delivery: true,
    vehicleCategory: true,
    geolocalisation: true,
    carwise: false
  },
  payment: {
    bankout: true,
    weeklyBankout: true,
    dailyBankout: true,
    bookingSafetyDeposit: true,
    damageSafetyDeposit: true,
    voucher: true
  },
  reporting: {
    vehicleStatus: true
  }
};

export const delayedBookingStatuses = [BOOKING_STATUS_COMPLETED, BOOKING_STATUS_IN_PROGRESS];
