import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { resolveActive, resolveSelected, showNextView, updateColumnFilters } from '../../../../components/_v2/ColumnList/helpers';
import ColumnList from '../../../../components/_v2/ColumnList';
import cs from 'classnames';
import Filters from './Filters';
import { exportBookingsListWithSearchV3, getBookingsList } from '../../../../actions/bookings-actions';
import { apiParams } from '../../../../constants/api-params-constants';
import { bookingFilterChips, bookingStatus, bookingTypes, vehicleUsage, vehicleUsageTypes } from '../../../../constants/options-constants';
import ArrowIcon from 'material-ui/svg-icons/navigation/arrow-forward';
import BookingListIcon from '../../../../components/BookingListIcon/BookingListIcon';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import { cssColors } from '../../../../constants/style-constants';
import FilterChips from '../../../../components/FilterChips/FilterChips';
import { addInfoMsg } from '../../../../utils/flashMessage/creator';
import { regexDate, regexEmail, regexGuid, regexName, regexPlateNumber, regexShortId, regexVoucherCode } from '../../../../constants/regex';
import { createElement } from '../../../../utils/component';
import moment from 'moment';
import { DMY_FORMAT, ISO_DATE_FLAT } from '../../../../constants/generic-constants';
import Fuse from 'fuse.js';

import {
  getAppFormattedDateTime,
  getFormValues,
  getShortId,
  getUriObj,
  hasValues,
  isObjEmpty,
  newProp,
  removeMultiSpace,
  safe
} from '../../../../utils/utils';

import {
  bookingsExportingSelector,
  bookingsFiltersSelector,
  bookingsFilterTypesSelector,
  bookingsLoadingSelector,
  bookingsResultsSelector,
  bookingsTotalResultsSelector,
  localeSelector,
  vehicleBrandsSelector,
  vehicleModelsSelector
} from '../../../../selectors/all-selectors';
import { URL_ALL } from '../../../../constants/backend-constants';

const fetchAmount = 50;
const fuseSearchKey = 'search';

const fuseOptions = {
  keys: [fuseSearchKey],
  minMatchCharLength: 3,
  threshold: 0.2
};

const getDatasetTypes = (childNodes, data) => {
  const { matches } = data;
  let indexes = { static: [] };

  for (let i = 0; i < matches.length; i++) {
    const { key, search, group } = matches[i].value;
    const node = childNodes[i];
    const item = group || key;

    if (node) {
      if (search) {
        if (!indexes[item]) indexes[item] = [];
        indexes[item].push(node);
      } else {
        indexes.static.push(node);
      }
    }
  }

  return indexes;
};

const addFuseResults = (results, fuse) => {
  for (let i = 0; i < fuse.length; i++) {
    results.push(fuse[i].item);
  }
};

const addSearchKey = (group, label) => ({
  search: group + ' ' + label,
  label
});

const getVehicleResults = (key, options) => {
  const group = getMsg(acLabels[key]);

  return options.map(({ name } = {}) => {
    return { key, value: name, ...addSearchKey(group, name) };
  });
};

const getModelResults = (models = []) => {
  return getVehicleResults('vehicleModel', models);
};

const getBrandResults = (brands = []) => {
  return getVehicleResults('vehicleBrand', brands);
};

const getErrorResults = () => {
  const group = 'error';
  const groupMsg = getMsg(acLabels[group]);

  return [
    {
      group,
      key: 'integrationFailed',
      value: true,
      ...addSearchKey(groupMsg, getMsg('bookings_integration_failed'))
    },
    {
      group,
      key: 'failed',
      value: true,
      ...addSearchKey(groupMsg, getMsg('bookings_failed'))
    },
    {
      group,
      key: 'rrsUpdateFailed',
      value: true,
      ...addSearchKey(groupMsg, getMsg('bookings_rrs_failed'))
    },
    {
      group,
      key: 'delayed',
      value: true,
      ...addSearchKey(groupMsg, getMsg('bookings_delayed_status'))
    }
  ];
};

const getUsageResults = () => {
  const key = 'vehicleUsageAtBookingCreation';
  const group = getMsg(acLabels[key]);

  return vehicleUsageTypes.map(item => {
    return { key, value: item.key, ...addSearchKey(group, getMsg(item.label)) };
  });
};

const getCommonResults = (key, options) => {
  const group = getMsg(acLabels[key]);

  return options.map(item => {
    return { key, value: item.value, ...addSearchKey(group, getMsg(item.labelKey)) };
  });
};

const getTypeResults = () => {
  return getCommonResults('type', bookingTypes);
};

const getStatusResults = () => {
  return getCommonResults('status', bookingStatus);
};

const acLabels = {
  vehicleRegistrationNumber: 'common_placeHolder_searchForPlateNumber',
  vehicleUsageAtBookingCreation: 'vehiclesFiltersForm_usage',
  voucherGroupName: 'common_searchFor_VoucherGroupName',
  voucherGroupIds: 'common_searchFor_voucherGroupId',
  voucherCode: 'common_searchFor_voucherCode',
  memberFirstName: 'common_first_name',
  memberLastName: 'common_last_name',
  memberLogin: 'common_email_address',
  bookingId: 'common_booking_id',
  startDateMin: 'booking_start_date_after',
  startDateMax: 'booking_start_date_before',
  vehicleBrand: 'vehiclesFiltersForm_brand',
  vehicleModel: 'vehiclesFiltersForm_model',
  endDateMin: 'booking_end_date_after',
  endDateMax: 'booking_end_date_before',
  static: 'common_search_by',
  status: 'common_status',
  error: 'common_error',
  type: 'common_type'
};

const acGuidResults = [{ key: 'bookingId' }, { key: 'voucherGroupIds' }];
const acNameResults = [{ key: 'memberFirstName' }, { key: 'memberLastName' }];
const acDateResults = [{ key: 'startDateMin' }, { key: 'startDateMax' }, { key: 'endDateMin' }, { key: 'endDateMax' }];
const acStaticResults = [{ key: 'voucherGroupName' }];

const dateFormatter = date => moment(date, DMY_FORMAT).format(ISO_DATE_FLAT);

const dateFormats = {
  startDateMin: dateFormatter,
  startDateMax: dateFormatter,
  endDateMin: dateFormatter,
  endDateMax: dateFormatter
};

const formatSearchSelect = (selection, input) => {
  const { key, value } = selection.value || {};
  const formatter = dateFormats[key];

  if (value) {
    return { [key]: value };
  }
  if (formatter) {
    return { [key]: formatter(input) };
  }
  return { [key]: input };
};

const appendDataset = (list, nodes, title, subTitle) => {
  const dataset = createElement('div', 'sc-dataset');
  const suggestions = createElement('div', 'sc-suggestions');

  if (title) {
    const header = createElement('div', 'sc-header');
    const titleEl = createElement('span', 'sc-title');

    titleEl.textContent = title;
    header.appendChild(titleEl);

    if (subTitle) {
      const subTitleEl = createElement('span', 'sc-value');
      subTitleEl.textContent = ' ' + subTitle;
      header.appendChild(subTitleEl);
    }

    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

class BookingsList extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
    this.updateMenu();
    this.initProps();
  }

  setVars() {
    this.state = {};
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const newLocale = newProp.call(this, props, 'locale');

    if (newProp.call(this, props, 'bookingsExporting')) {
      this.updateMenu({ exporting: props.bookingsExporting });
    }

    if (newLocale) {
      this.fuseStatus = new Fuse(getStatusResults(), fuseOptions);
      this.fuseUsage = new Fuse(getUsageResults(), fuseOptions);
      this.fuseType = new Fuse(getTypeResults(), fuseOptions);
      this.fuseError = new Fuse(getErrorResults(), fuseOptions);
    }

    if (newLocale || newProp.call(this, props, 'vehicleBrands')) {
      this.fuseBrand = new Fuse(getBrandResults(props.vehicleBrands), fuseOptions);
    }

    if (newLocale || newProp.call(this, props, 'vehicleModels')) {
      this.fuseModel = new Fuse(getModelResults(props.vehicleModels), fuseOptions);
    }
  }

  detailsOpen() {
    return safe(() => this.props.params.bookingId);
  }

  updateMenu({ exporting } = {}) {
    this.setState({
      menuItems: [
        {
          id: 'bookings_export_button',
          labelKey: exporting ? 'common_button_exporting' : 'bookings_export_button',
          icon: <DownloadIcon color={cssColors.listItem} />,
          handleClick: this.handleExportBookings,
          disabled: exporting
        }
      ]
    });
  }

  setCallbacks() {
    this.handleEdit = (data = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'bookingDetails', ':bookingId', data.id, openInNewTab);
    };

    this.handleExportBookings = () => {
      if (!this.props.bookingsExporting) {
        addInfoMsg(getMsg('common_button_exporting'));
        this.props.dispatch(exportBookingsListWithSearchV3(this.props.filters));
      }
    };

    this.getAcList = (list, data) => {
      const { static: regex, ...types } = getDatasetTypes(list.childNodes, data);
      const labelPrefix = getMsg(acLabels.static);

      for (const type in types) {
        const subTitle = `(${getMsg(acLabels[type])})`;
        appendDataset(list, types[type], labelPrefix, subTitle);
      }

      if (regex.length) {
        appendDataset(list, regex, labelPrefix);
      }
    };

    this.getAcResult = (item, { value: data }) => {
      const { key, label } = data || {};
      const children = [];

      const labelKey = acLabels[key];
      const result = createElement('span', 'sc-label');

      result.innerHTML = label ? label : getMsg(labelKey);
      children.push(result);

      item.replaceChildren(...children);
    };

    this.getAcData = query => {
      return new Promise(resolve => {
        const results = [];

        query = removeMultiSpace(query);
        if (!query) return resolve(results);

        addFuseResults(results, this.fuseError.search(query));
        addFuseResults(results, this.fuseStatus.search(query));
        addFuseResults(results, this.fuseUsage.search(query));
        addFuseResults(results, this.fuseType.search(query));
        addFuseResults(results, this.fuseBrand.search(query));
        addFuseResults(results, this.fuseModel.search(query));

        if (regexShortId.test(query)) {
          results.push({ key: 'bookingId' });
        }
        if (regexGuid.test(query)) {
          results.push(...acGuidResults);
        }
        if (regexEmail.test(query)) {
          results.push({ key: 'memberLogin' });
        }
        if (regexDate.test(query)) {
          results.push(...acDateResults);
        }
        if (regexName.test(query)) {
          results.push(...acNameResults);
        }
        if (regexPlateNumber.test(query)) {
          results.push({ key: 'vehicleRegistrationNumber' });
        }
        if (regexVoucherCode.test(query)) {
          results.push({ key: 'voucherCode' });
        }

        results.push(...acStaticResults);
        resolve(results);
      });
    };

    this.getMoreResults = () => {
      if (this.props.loading) return;

      const { list, totalResults, dispatch } = this.props;
      const currResults = safe(() => list.length) || 0;

      if (totalResults && totalResults > currResults) {
        const params = safe(() => getUriObj(this.props.params.bookingsFilters));
        const diff = totalResults - currResults;
        const increase = fetchAmount > diff ? diff : fetchAmount;
        const size = currResults + increase;

        dispatch(getBookingsList({ ...apiParams.bookingsList, ...params, page: { number: 1, size } }));
      }
    };

    this.handleDeleteChip = item => {
      const { [item]: omit, ...filters } = this.props.filters;
      const values = isObjEmpty(filters) ? URL_ALL : filters;

      updateColumnFilters.call(this, values);
    };

    this.handleSearchSelect = (event = {}) => {
      const { query = '', selection = {} } = event.detail || {};
      const data = formatSearchSelect(selection, removeMultiSpace(query));
      updateColumnFilters.call(this, { ...this.props.filters, ...data });
    };

    this.handleFiltersApply = () => {
      const values = getFormValues('bookingsFilters');

      if (hasValues(values)) {
        updateColumnFilters.call(this, values);
      } else {
        updateColumnFilters.call(this, URL_ALL);
      }
    };

    this.handleFiltersReset = () => {
      updateColumnFilters.call(this, URL_ALL);
    };

    this.renderItem = b => {
      const bookingUsage = getMsg(vehicleUsage[b.vehicleUsageAtBookingCreation] || 'common_unknown');
      const userName = ((b.memberFirstName || '') + ' ' + (b.memberLastName || '')).trim();
      const startTime = getAppFormattedDateTime(b.startDate, { v2: true });
      const endTime = getAppFormattedDateTime(b.endDate, { v2: true });
      const vehicleName = b.vehicleBrandName + ' ' + b.vehicleModelName;
      const safeName = userName || getShortId(b.memberId);
      const bookingId = getShortId(b.id);

      return (
        <div className="sc-list-items">
          <div className={cs('sc-list-item', 'booking-id')}>
            <BookingListIcon type={b.carSharingUsageType || b.type} status={b.status} />
            <span title={bookingId}>{bookingId}</span>
          </div>
          <div className="sc-list-item">
            <div className="sc-multi-item">
              <span title={vehicleName}>{vehicleName}</span>
              <span title={b.vehicleRegistrationNumber}>{b.vehicleRegistrationNumber}</span>
            </div>
          </div>
          <div className="sc-list-item">
            <span title={bookingUsage}>{bookingUsage}</span>
          </div>
          <div className="sc-list-item">
            <span title={safeName}>{safeName}</span>
          </div>
          <div className="sc-list-item">
            <div className="sc-multi-item">
              <div className="sc-dates">
                <span title={startTime}>{startTime}</span>
                <ArrowIcon className="sc-icon" />
                <span title={endTime}>{endTime}</span>
              </div>
              <span title={b.startParkingName}>{b.startParkingName}</span>
            </div>
          </div>
        </div>
      );
    };

    this.renderHeader = () => {
      const bookingId = getMsg('bookings_tableView_label_bookingId');
      const bookingUsage = getMsg('vehiclesFiltersForm_usage');
      const memberName = getMsg('feedbacks_tableView_label_memberName');
      const vehicle = getMsg('feedbacks_tableView_label_vehicle');
      const dates = getMsg('booking_departure_arrival');

      return (
        <div className="sc-list-header">
          <div className="sc-item-wrap">
            <div className="sc-list-item">
              <span title={bookingId}>{bookingId}</span>
            </div>
            <div className="sc-list-item">
              <span title={vehicle}>{vehicle}</span>
            </div>
            <div className="sc-list-item">
              <span title={bookingUsage}>{bookingUsage}</span>
            </div>
            <div className="sc-list-item">
              <span title={memberName}>{memberName}</span>
            </div>
            <div className="sc-list-item">
              <span title={dates}>{dates}</span>
            </div>
          </div>
        </div>
      );
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
  }

  getFilters() {
    return <Filters initialValues={this.props.filters} />;
  }

  getFilterChips() {
    if (!this.detailsOpen()) {
      return (
        <FilterChips
          id="bookings-chips"
          chipClass="v2-filter-chip"
          urlParams={this.props.filters}
          onDeleteChip={this.handleDeleteChip}
          translations={bookingFilterChips}
          filterTypes={this.props.filterTypes}
        />
      );
    }
  }

  render() {
    const title = <FormattedMessage id="side_menu_section_booking" />;

    return (
      <ColumnList
        placeholder={getMsg('placeholder.bookings.search')}
        title={title}
        filtersTitle={title}
        filters={this.getFilters()}
        filterChips={this.getFilterChips()}
        onFiltersApply={this.handleFiltersApply}
        onFiltersReset={this.handleFiltersReset}
        onScrollToBottom={this.getMoreResults}
        renderItem={this.renderItem}
        header={this.renderHeader()}
        onSelect={this.handleEdit}
        filtersActive={!!this.props.filters}
        totalResults={this.props.totalResults}
        items={this.props.list}
        inputRef={this.props.inputRef}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.state.menuItems}
        autoCompleteEnabled
        autoCompleteData={this.getAcData}
        autoCompleteList={this.getAcList}
        autoCompleteResult={this.getAcResult}
        autoCompleteOnSelect={this.handleSearchSelect}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: localeSelector(state),
    list: bookingsResultsSelector(state),
    filters: bookingsFiltersSelector(state),
    totalResults: bookingsTotalResultsSelector(state),
    loading: bookingsLoadingSelector(state),
    filterTypes: bookingsFilterTypesSelector(state),
    bookingsExporting: bookingsExportingSelector(state),
    vehicleBrands: vehicleBrandsSelector(state),
    vehicleModels: vehicleModelsSelector(state)
  };
};

BookingsList = connect(mapStateToProps)(BookingsList);

export default BookingsList;
