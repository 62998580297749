import {
  SITE_SMARTCARD_SPONTANEOUS_USAGE_BUSINESS,
  SITE_SMARTCARD_SPONTANEOUS_USAGE_PRIVATE,
  SMARTCARDS_EVENTS_RESULT_SUCCESS,
  SMARTCARDS_EVENTS_RESULT_FAILURE_EVENT_TIME_OUT,
  SMARTCARDS_EVENTS_RESULT_FAILURE_DUPLICATED_DEVICE_ID,
  SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_STATUS_UNKNOWN,
  SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_REGISTERED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_USER_NOT_ACTIVATED_OR_SUSPENDED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_DIFFERENT_COMPANIES,
  SMARTCARDS_EVENTS_RESULT_FAILURE_VEHICLE_NOT_REGISTERED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_NO_VEHICLE_SITE,
  SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_ALLOWED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_DEACTIVATED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_NO_BOOKING,
  SMARTCARDS_EVENTS_RESULT_FAILURE_UNLOCK_ERROR,
  SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_ERROR,
  SMARTCARDS_EVENTS_RESULT_FAILURE_NO_SPONTANEOUS_BOOKING_AVAILABILITY,
  SMARTCARDS_EVENTS_RESULT_FAILURE_NO_USER_ASSIGNED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_DENIED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_NO_ANSWER,
  SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_ANOTHER_SMARTCARD_ASSIGNED,
  SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_TIME_OUT,
  SMARTCARDS_EVENTS_RESULT_FAILURE_UNKNOWN_ERROR,
  SMARTCARDS_EVENTS_RESULT_FISHING_CALL_DONE,
  SMARTCARDS_EVENTS_ACTION_OPEN,
  SMARTCARDS_EVENTS_ACTION_CLOSE,
  SMARTCARDS_EVENTS_ACTION_UNKNOWN,
  COMPANY_ACCESS_PRIVATE,
  COMPANY_ACCESS_PUBLIC,
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_YES,
  STATUS_APPLIED,
  STATUS_TO_COMPLETE,
  STATUS_TO_REVIEW,
  STATUS_APPROVED,
  STATUS_NOT_APPROVED,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_PRE_BOOKED,
  STATUS_CANCELED,
  VEHICLE_DAMAGE_STATUS_LIST_ACTIVE,
  VEHICLE_DAMAGE_STATUS_LIST_REPAIRED,
  VEHICLE_DAMAGE_STATUS_LIST_DUPLICATED,
  VEHICLE_DAMAGE_STATUS_LIST_WONT_REPAIR,
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BE_ALL,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_FIELD_AGENT,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT,
  PERCENT_DISCOUNT,
  FIXED_AMOUNT_DISCOUNT,
  BOOKING_TYPE_CAR_SHARING,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_TYPE_GROUP_SHARING,
  BOOKING_TYPE_RRS_SHARING,
  BOOKING_TYPE_FREE_FLOATING,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_UNPAID,
  BOOKING_STATUS_PARTIALY_PAID,
  STATUS_REFUSED,
  STATUS_ERROR,
  STATUS_CAPTURED,
  STATUS_SETTLED,
  STATUS_SENT_FOR_REFUND,
  STATUS_REFUNDED,
  STATUS_REVOKED,
  STATUS_CANCELLED,
  STATUS_AUTHORISED,
  STATUS_EXPIRED,
  TRANSACTION_CAPTURED_FAILED,
  TRANSACTION_CHARGEBACK_REVERSED,
  TRANSACTION_CHARGED_BACK,
  TRANSACTION_INFORMATION_REQUESTED,
  TRANSACTION_INFORMATION_SUPPLIED,
  TRANSACTION_REFUND_FAILED,
  TRANSACTION_REVOKE_FAILED,
  TRANSACTION_REVOKE_REQUESTED,
  TRANSACTION_CAPTURE_REQUESTED,
  TRANSACTION_REFUND_REQUESTED,
  TRANSACTION_TYPE_BILLING,
  TRANSACTION_TYPE_DEPOSIT,
  TRANSACTION_TYPE_REFUND,
  VEHICLE_DAMAGE_TYPE_START,
  VEHICLE_DAMAGE_TYPE_END,
  MEMBER_TYPE_DUPLICATE_NAME_MSG,
  PRICING_SLOTS_INVALID_DURATION_MSG,
  PRICING_SLOTS_OVERLAP_MSG,
  VEHICLE_STATUS_IN_THE_PAST_MSG,
  VEHICLE_STATUS_OVERLAP_CODE,
  VEHICLE_STATUS_15_MINUTES_MSG,
  VEHICLE_STATUSES,
  BOOKING_EVENT_STATUSES,
  BOOKING_EVENT_TYPES,
  MEMBER_SUBSCRIPTION_ORIGIN_WEB_FORM,
  MEMBER_SUBSCRIPTION_ORIGIN_MOBILE_FORM,
  MEMBER_SUBSCRIPTION_ORIGIN_IMPORT,
  MEMBER_SUBSCRIPTION_ORIGIN_RRS,
  MEMBER_SUBSCRIPTION_ORIGIN_PUBLIC_API,
  FORMATTED_ADDRESS_MISSING,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_CRM,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BANKOUT_STATUS_EXECUTED,
  BANKOUT_STATUS_PENDING,
  BANKOUT_STATUS_PROCESSING,
  BANKOUT_STATUS_SUCCESS,
  BANKOUT_STATUS_ERROR,
  BILLING_ENTITY_TYPE_SUPER_COMPANY,
  BILLING_ENTITY_TYPE_SUPER_COMPANY_ALT,
  BILLING_ENTITY_TYPE_COMPANY,
  PAYBACK_COUNTRIES,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  VEHICLE_USAGE_TYPE_NO_USAGE,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE,
  VEHICLE_USAGE_TYPE_DELIVERY,
  EXTERNAL_INVOICE,
  INVOICE_PARAMS_SOCIO_UNIQUE,
  INVOICE_PARAMS_SOCIO_MULTIPLE,
  INVOICE_PARAMS_IN_LIQUIDATION,
  INVOICE_PARAMS_NOT_IN_LIQUIDATION,
  VEHICLE_FUEL_TYPE_DIESEL,
  VEHICLE_FUEL_TYPE_ELECTRIC,
  VEHICLE_FUEL_TYPE_HYBRID,
  VEHICLE_FUEL_TYPE_LPG,
  VEHICLE_FUEL_TYPE_PETROL,
  VEHICLE_TASK_TYPE_MAINTENANCE_SERVICE,
  VEHICLE_TASK_TYPE_MOVE_VEHICLE,
  VEHICLE_TASK_TYPE_REPAIR,
  VEHICLE_TASK_TYPE_CLEANING,
  VEHICLE_TASK_TYPE_CHARGING,
  VEHICLE_TASK_STATUS_WAITING_FOR_FIELD_AGENT,
  VEHICLE_TASK_STATUS_IN_PROGRESS,
  VEHICLE_TASK_STATUS_DONE,
  VEHICLE_SYSTEM_INVERS,
  VEHICLE_SYSTEM_MOVINBLUE,
  VEHICLE_SYSTEM_VIRTUALKEY,
  MOBILE_ACTIONS,
  ROUND_TRIP,
  ONE_WAY_TRIP,
  FREQUENCY_DAILY,
  FREQUENCY_WEEKLY,
  FREQUENCY_MONTHLY,
  SCHEDULED_EXPORT,
  PAYMENT_PROVIDERS
} from './backend-constants';
import routes from './routes-constants';

import { smartcardsUserSearchTypes } from './generic-constants';

export const hourOptions = [
  { label: '00', value: '0' },
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 }
];

export const minuteOptions = [
  { label: '00', value: '0' },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 }
];

export const smartcardsEventsActionTypes = [
  {
    value: SMARTCARDS_EVENTS_ACTION_OPEN,
    labelKey: 'smartcardsEvents_action_OPEN'
  },
  {
    value: SMARTCARDS_EVENTS_ACTION_CLOSE,
    labelKey: 'smartcardsEvents_action_CLOSE'
  },
  {
    value: SMARTCARDS_EVENTS_ACTION_UNKNOWN,
    labelKey: 'smartcardsEvents_action_UNKNOWN'
  }
];

export const memberSubscriptionOriginOptions = [
  {
    value: MEMBER_SUBSCRIPTION_ORIGIN_WEB_FORM,
    labelKey: 'member_subscription_origin_web_form'
  },
  {
    value: MEMBER_SUBSCRIPTION_ORIGIN_MOBILE_FORM,
    labelKey: 'member_subscription_origin_mobile_form'
  },
  {
    value: MEMBER_SUBSCRIPTION_ORIGIN_IMPORT,
    labelKey: 'member_subscription_origin_import'
  },
  {
    value: MEMBER_SUBSCRIPTION_ORIGIN_RRS,
    labelKey: 'member_subscription_origin_rrs'
  },
  {
    value: MEMBER_SUBSCRIPTION_ORIGIN_PUBLIC_API,
    labelKey: 'member_subscription_origin_public_api'
  }
];

export const memberSubscriptionOriginLabel = {
  [MEMBER_SUBSCRIPTION_ORIGIN_WEB_FORM]: 'member_subscription_origin_web_form',
  [MEMBER_SUBSCRIPTION_ORIGIN_MOBILE_FORM]: 'member_subscription_origin_mobile_form',
  [MEMBER_SUBSCRIPTION_ORIGIN_IMPORT]: 'member_subscription_origin_import',
  [MEMBER_SUBSCRIPTION_ORIGIN_RRS]: 'member_subscription_origin_rrs',
  [MEMBER_SUBSCRIPTION_ORIGIN_PUBLIC_API]: 'member_subscription_origin_public_api'
};

export const smartcardsEventsResultTypes = [
  {
    value: SMARTCARDS_EVENTS_RESULT_SUCCESS,
    labelKey: 'smartcardsEvents_result_SUCCESS'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_EVENT_TIME_OUT,
    labelKey: 'smartcardsEvents_result_FAILURE_EVENT_TIME_OUT'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_DUPLICATED_DEVICE_ID,
    labelKey: 'smartcardsEvents_result_FAILURE_DUPLICATED_DEVICE_ID'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_STATUS_UNKNOWN,
    labelKey: 'smartcardsEvents_result_FAILURE_LOCK_STATUS_UNKNOWN'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_REGISTERED,
    labelKey: 'smartcardsEvents_result_FAILURE_SMARTCARD_NOT_REGISTERED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_USER_NOT_ACTIVATED_OR_SUSPENDED,
    labelKey: 'smartcardsEvents_result_FAILURE_USER_NOT_ACTIVATED_OR_SUSPENDED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_DIFFERENT_COMPANIES,
    labelKey: 'smartcardsEvents_result_FAILURE_DIFFERENT_COMPANIES'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_VEHICLE_NOT_REGISTERED,
    labelKey: 'smartcardsEvents_result_FAILURE_VEHICLE_NOT_REGISTERED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_NO_VEHICLE_SITE,
    labelKey: 'smartcardsEvents_result_FAILURE_NO_VEHICLE_SITE'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_ALLOWED,
    labelKey: 'smartcardsEvents_result_FAILURE_SMARTCARD_NOT_ALLOWED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_DEACTIVATED,
    labelKey: 'smartcardsEvents_result_FAILURE_SMARTCARD_DEACTIVATED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_NO_BOOKING,
    labelKey: 'smartcardsEvents_result_FAILURE_NO_BOOKING'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_UNLOCK_ERROR,
    labelKey: 'smartcardsEvents_result_FAILURE_UNLOCK_ERROR'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_ERROR,
    labelKey: 'smartcardsEvents_result_FAILURE_LOCK_ERROR'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_NO_SPONTANEOUS_BOOKING_AVAILABILITY,
    labelKey: 'smartcardsEvents_result_FAILURE_NO_SPONTANEOUS_BOOKING_AVAILABILITY'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_NO_USER_ASSIGNED,
    labelKey: 'smartcardsEvents_result_FAILURE_NO_USER_ASSIGNED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_DENIED,
    labelKey: 'smartcardsEvents_result_FAILURE_FISHING_DENIED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_NO_ANSWER,
    labelKey: 'smartcardsEvents_result_FAILURE_FISHING_NO_ANSWER'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_ANOTHER_SMARTCARD_ASSIGNED,
    labelKey: 'smartcardsEvents_result_FAILURE_FISHING_ANOTHER_SMARTCARD_ASSIGNED'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_TIME_OUT,
    labelKey: 'smartcardsEvents_result_FAILURE_FISHING_TIME_OUT'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FAILURE_UNKNOWN_ERROR,
    labelKey: 'smartcardsEvents_result_FAILURE_UNKNOWN_ERROR'
  },
  {
    value: SMARTCARDS_EVENTS_RESULT_FISHING_CALL_DONE,
    labelKey: 'smartcardsEvents_result_FISHING_CALL_DONE'
  }
];

export const booleanOptions = [
  {
    labelKey: 'common_yes',
    value: 'true'
  },
  {
    labelKey: 'common_no',
    value: 'false'
  }
];

export const booleanOptionsCF = [
  {
    labelKey: 'common_yes',
    value: CUSTOM_FIELD_YES
  },
  {
    labelKey: 'common_no',
    value: 'NO'
  }
];

export const booleanOptionsSocioUnico = [
  {
    labelKey: 'common_yes',
    value: INVOICE_PARAMS_SOCIO_UNIQUE
  },
  {
    labelKey: 'common_no',
    value: INVOICE_PARAMS_SOCIO_MULTIPLE
  }
];

export const booleanOptionsLiquidation = [
  {
    labelKey: 'invoice_params_italian_liquidation_pending',
    value: INVOICE_PARAMS_IN_LIQUIDATION
  },
  {
    labelKey: 'invoice_params_italian_liquidation_not_pending',
    value: INVOICE_PARAMS_NOT_IN_LIQUIDATION
  }
];

export const threeValuesOptions = [
  {
    labelKey: 'common_yes',
    value: CUSTOM_FIELD_YES
  },
  {
    labelKey: 'common_conditioned',
    value: 'CONDITIONED'
  },
  {
    labelKey: 'common_no',
    value: 'NO'
  }
];

export const customFieldTypes = [
  { labelKey: 'customFieldType_TEXT', value: CUSTOM_FIELD_TYPE_TEXT },
  { labelKey: 'customFieldType_NUMERIC', value: CUSTOM_FIELD_TYPE_NUMERIC },
  { labelKey: 'customFieldType_BOOLEAN', value: CUSTOM_FIELD_TYPE_BOOLEAN },
  { labelKey: 'customFieldType_PHONE_NUMBER', value: CUSTOM_FIELD_TYPE_PHONE_NUMBER },
  { labelKey: 'customFieldType_FILE', value: CUSTOM_FIELD_TYPE_FILE }
];

export const allowOptions = [
  {
    labelKey: 'common_allow',
    value: 'true'
  },
  {
    labelKey: 'common_disallow',
    value: 'false'
  }
];

export const spontaneousBookingUsageTypes = [
  {
    labelKey: 'siteForm_spontaneousBookingUsage_BUSINESS',
    value: SITE_SMARTCARD_SPONTANEOUS_USAGE_BUSINESS
  },
  {
    labelKey: 'siteForm_spontaneousBookingUsage_PRIVATE',
    value: SITE_SMARTCARD_SPONTANEOUS_USAGE_PRIVATE
  }
];

export const resetPasswordDeliveryOptions = [
  {
    labelKey: 'resetPassword_deliveryMode_sms',
    value: 'SMS'
  },
  {
    labelKey: 'resetPassword_deliveryMode_email',
    value: 'EMAIL'
  }
];

export const memberStatusOptions = [
  {
    labelKey: 'members_status_' + STATUS_APPLIED.toLowerCase(),
    value: STATUS_APPLIED
  },
  {
    labelKey: 'members_status_' + STATUS_TO_COMPLETE.toLowerCase(),
    value: STATUS_TO_COMPLETE
  },
  {
    labelKey: 'members_status_' + STATUS_TO_REVIEW.toLowerCase(),
    value: STATUS_TO_REVIEW
  },
  {
    labelKey: 'members_status_' + STATUS_APPROVED.toLowerCase(),
    value: STATUS_APPROVED
  },
  {
    labelKey: 'members_status_' + STATUS_NOT_APPROVED.toLowerCase(),
    value: STATUS_NOT_APPROVED
  }
];

export const bookingStatus = [
  {
    labelKey: 'bookings_tableView_status_COMPLETED',
    value: BOOKING_STATUS_COMPLETED
  },
  {
    labelKey: 'bookings_tableView_status_CANCELED',
    value: STATUS_CANCELED
  },
  {
    labelKey: 'bookings_tableView_status_IN_PROGRESS',
    value: BOOKING_STATUS_IN_PROGRESS
  },
  {
    labelKey: 'bookings_tableView_status_SCHEDULED',
    value: BOOKING_STATUS_SCHEDULED
  },
  {
    labelKey: 'bookings_tableView_status_PRE_BOOKED',
    value: BOOKING_STATUS_PRE_BOOKED
  }
];

export const backUsersRolesStatic = [
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_ROOT,
    value: BACKUSER_ROLE_ROOT
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_SUPER_ADMIN,
    value: BACKUSER_ROLE_SUPER_ADMIN
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_ADMIN,
    value: BACKUSER_ROLE_ADMIN
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_ZONE_MANAGER,
    value: BACKUSER_ROLE_ZONE_MANAGER
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_ADMIN_DEALER,
    value: BACKUSER_ROLE_ADMIN_DEALER
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_CALL_CENTER_OPERATOR,
    value: BACKUSER_ROLE_CALL_CENTER_OPERATOR
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_CRM,
    value: BACKUSER_ROLE_CRM
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_SERVICE_ADVISOR,
    value: BACKUSER_ROLE_SERVICE_ADVISOR
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_FLEET_MANAGER,
    value: BACKUSER_ROLE_FLEET_MANAGER
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_FIELD_AGENT,
    value: BACKUSER_ROLE_FIELD_AGENT
  },
  {
    labelKey: 'backUsers_role_' + BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
    value: BACKUSER_ROLE_EXTERNAL_FINE_COMPANY
  }
];

export const withCleanlinessProblemsOptions = [
  {
    labelKey: 'feedbacks_archive_status_all',
    value: BE_ALL
  },
  {
    labelKey: 'feedbacks_archive_status_with',
    value: 'WITH'
  },
  {
    labelKey: 'feedbacks_archive_status_without',
    value: 'WITHOUT'
  }
];

export const feedbackStatusOptions = [
  {
    labelKey: 'feedbacks_detail_feedback_status_NEW',
    value: 'NEW'
  },
  {
    labelKey: 'feedbacks_detail_feedback_status_SEEN',
    value: 'SEEN'
  },
  {
    labelKey: 'feedbacks_detail_feedback_status_HANDLED',
    value: 'HANDLED'
  }
];

export const allDelayedStatues = [
  {
    labelKey: 'bookings_delayed_status_yes',
    value: true
  },
  {
    labelKey: 'bookings_delayed_status_no',
    value: false
  }
];

export const allSystemTypes = [
  {
    value: VEHICLE_SYSTEM_INVERS,
    label: 'Invers'
  },
  {
    value: VEHICLE_SYSTEM_MOVINBLUE,
    label: "Mov'InBlue"
  },
  {
    value: VEHICLE_SYSTEM_VIRTUALKEY,
    label: 'Virtual Key'
  }
];

export const allFailedOptions = [
  {
    labelKey: 'bookings_failed_yes',
    value: true
  },
  {
    labelKey: 'bookings_failed_no',
    value: false
  }
];

export const quickSightTabFields = [
  'completedBooking',
  'averageDuration',
  'averageDistance',
  'canceledBooking',
  'lateBooking',
  'lateBookingOvertime',
  'bookingPerVehicle',
  'businessPrivate',
  'turnover',
  'activeMember',
  'newMembers'
];

export const otherReportingFields = ['vehicleStatus', 'quickSight'];

export const mapReportingFieldsToPaths = {
  completedBooking: routes.qsCompletedBooking.path,
  averageDuration: routes.qsAverageDuration.path,
  averageDistance: routes.qsAverageDistance.path,
  canceledBooking: routes.qsCanceledBooking.path,
  lateBooking: routes.qsLateBooking.path,
  lateBookingOvertime: routes.qsLateBookingOvertime.path,
  bookingPerVehicle: routes.qsBookingPerVehicle.path,
  businessPrivate: routes.qsBusinessVsPrivate.path,
  turnover: routes.qsTurnover.path,
  activeMember: routes.qsActiveMembers.path,
  newMembers: routes.qsNewMembers.path
};

export const quickSightIds = {
  [routes.qsCompletedBooking.path]: {
    titleKey: 'reporting.feature.completedBooking',
    infoKey: 'reporting.feature.completedBooking.info',
    dashboardId: '03cdc6bd-9fa2-48f8-9235-1b83ab4ae996'
  },
  [routes.qsAverageDuration.path]: {
    titleKey: 'reporting.feature.averageDuration',
    infoKey: 'reporting.feature.averageDuration.info',
    dashboardId: '8a23e23a-8446-4389-a3ad-e84584a26f16'
  },
  [routes.qsAverageDistance.path]: {
    titleKey: 'reporting.feature.averageDistance',
    infoKey: 'reporting.feature.averageDistance.info',
    dashboardId: '4a299c3b-0d3a-4fa0-b86b-9bedf764c385'
  },
  [routes.qsCanceledBooking.path]: {
    titleKey: 'reporting.feature.canceledBooking',
    infoKey: 'reporting.feature.canceledBooking.info',
    dashboardId: 'fa8e5aaa-becf-4e16-891b-99872b319c09'
  },
  [routes.qsLateBooking.path]: {
    titleKey: 'reporting.feature.lateBooking',
    infoKey: 'reporting.feature.lateBooking.info',
    dashboardId: '00ab56cc-6aa0-49cb-aa51-c981bbeb095f'
  },
  [routes.qsLateBookingOvertime.path]: {
    titleKey: 'reporting.feature.lateBookingOvertime',
    infoKey: 'reporting.feature.lateBookingOvertime.info',
    dashboardId: 'df278156-22c2-4b2c-911d-5597675b5561'
  },
  [routes.qsBookingPerVehicle.path]: {
    titleKey: 'reporting.feature.bookingPerVehicle',
    infoKey: 'reporting.feature.bookingPerVehicle.info',
    dashboardId: 'e70f12ff-be6e-4305-b59b-119eea25e093'
  },
  [routes.qsBusinessVsPrivate.path]: {
    titleKey: 'reporting.feature.businessPrivate',
    infoKey: 'reporting.feature.businessPrivate.info',
    dashboardId: 'a7a72119-92ab-4711-851a-85e06005a4b0'
  },
  [routes.qsTurnover.path]: {
    titleKey: 'reporting.feature.turnover',
    infoKey: 'reporting.feature.turnover.info',
    dashboardId: '6f1dcb3c-c69f-4e27-b88d-e83464ed5522'
  },
  [routes.qsActiveMembers.path]: {
    titleKey: 'reporting.feature.activeMember',
    infoKey: 'reporting.feature.activeMember.info',
    dashboardId: 'f30272e2-ee5d-439e-8c15-d56ee59d9d6c'
  },
  [routes.qsNewMembers.path]: {
    titleKey: 'reporting.feature.newMembers',
    infoKey: 'reporting.feature.newMembers.info',
    dashboardId: '7723d17e-9c44-424b-b8d1-c51e6a8cd6dd'
  }
};

export const statusList = {
  ready: 'ready',
  inactive: 'inactive',
  reparation: 'reparation',
  cleaning: 'cleaning',
  intervention: 'intervention',
  maintenance: 'maintenance',
  rented_in_rrs: 'rented_in_rrs'
};

export const statusListLabels = [
  {
    labelKey: 'vehicle_statusType_READY',
    value: statusList.ready.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_INACTIVE',
    value: statusList.inactive.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_REPARATION',
    value: statusList.reparation.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_CLEANING',
    value: statusList.cleaning.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_INTERVENTION',
    value: statusList.intervention.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_MAINTENANCE',
    value: statusList.maintenance.toUpperCase()
  },
  {
    labelKey: 'vehicle_statusType_RENTED_IN_RRS',
    value: statusList.rented_in_rrs.toUpperCase()
  }
];

export const defaultParamsPage = {
  page: {
    number: 1,
    size: 5
  }
};

export const allDamagesStatues = [
  {
    labelKey: 'vehicleDamages_status_ACTIVE',
    value: VEHICLE_DAMAGE_STATUS_LIST_ACTIVE
  },
  {
    labelKey: 'vehicleDamages_status_REPAIRED',
    value: VEHICLE_DAMAGE_STATUS_LIST_REPAIRED
  },
  {
    labelKey: 'vehicleDamages_status_DUPLICATED',
    value: VEHICLE_DAMAGE_STATUS_LIST_DUPLICATED
  },
  {
    labelKey: 'vehicleDamages_status_WONT_REPAIR',
    value: VEHICLE_DAMAGE_STATUS_LIST_WONT_REPAIR
  }
];

export const bookingUsageTypes = [
  { labelKey: 'bookingType_BUSINESS', value: BOOKING_USAGE_TYPE_BUSINESS },
  { labelKey: 'bookingType_PRIVATE', value: BOOKING_USAGE_TYPE_PRIVATE },
  { labelKey: 'bookingType_ALL', value: BE_ALL }
];

export const usageTypesOptions = [
  { labelKey: 'bookingType_BUSINESS', value: BOOKING_USAGE_TYPE_BUSINESS },
  { labelKey: 'bookingType_PRIVATE', value: BOOKING_USAGE_TYPE_PRIVATE }
];

export const mobileHomepageActionsOptions = [
  { labelKey: 'mobile.action.deeplink', value: MOBILE_ACTIONS.DEEPLINK },
  { labelKey: 'mobile.action.information', value: MOBILE_ACTIONS.INFORMATION },
  { labelKey: 'mobile.action.invitation', value: MOBILE_ACTIONS.INVITATION }
];

export const sizeOptions = [
  { labelKey: 'mobile.homepage.size.primary', value: 'primary' },
  { labelKey: 'mobile.homepage.size.secondary', value: 'secondary' },
  { labelKey: 'mobile.homepage.size.default', value: 'default' }
];

export const prefillOptions = [
  { labelKey: 'mobile.homepage.prefill.general', value: 'general' },
  { labelKey: 'mobile.homepage.prefill.business', value: 'business' },
  { labelKey: 'mobile.homepage.prefill.private', value: 'private' }
];
export const bookingTypes = [
  { labelKey: 'bookings_tableView_serviceType_CAR_SHARING', value: BOOKING_TYPE_CAR_SHARING },
  { labelKey: 'bookings_tableView_serviceType_RIDE_SHARING', value: BOOKING_TYPE_RIDE_SHARING },
  { labelKey: 'bookings_tableView_serviceType_GROUP_SHARING', value: BOOKING_TYPE_GROUP_SHARING },
  { labelKey: 'bookings_tableView_serviceType_RRS_SHARING', value: BOOKING_TYPE_RRS_SHARING }
];

export const smartcardsUserSearchOptions = [
  { labelKey: 'backUserForm_email_label', value: smartcardsUserSearchTypes.EMAIL },
  { labelKey: 'common_last_name', value: smartcardsUserSearchTypes.LAST_NAME },
  { labelKey: 'common_first_name', value: smartcardsUserSearchTypes.FIRST_NAME }
];

export const bookingTypesObj = {
  [BOOKING_TYPE_CAR_SHARING]: 'bookings_tableView_serviceType_CAR_SHARING',
  [BOOKING_TYPE_RIDE_SHARING]: 'bookings_tableView_serviceType_RIDE_SHARING',
  [BOOKING_TYPE_GROUP_SHARING]: 'bookings_tableView_serviceType_GROUP_SHARING',
  [BOOKING_TYPE_RRS_SHARING]: 'bookings_tableView_serviceType_RRS_SHARING',
  [BOOKING_TYPE_FREE_FLOATING]: 'bookings_tableView_serviceType_FREE_FLOATING'
};

export const bookingUsageTypeObj = {
  [BOOKING_USAGE_TYPE_BUSINESS]: 'siteForm_spontaneousBookingUsage_BUSINESS',
  [BOOKING_USAGE_TYPE_PRIVATE]: 'siteForm_spontaneousBookingUsage_PRIVATE'
};

export const voucherTypeLabels = {
  [FIXED_AMOUNT_DISCOUNT]: 'voucher_type_flat_value',
  [PERCENT_DISCOUNT]: 'voucher_type_percentage'
};

export const colProps = { xs: 6, md: 6 };

export const voucherCodeTypes = [
  {
    labelKey: 'voucher_create_codes_option1',
    value: 'individual'
  },
  {
    labelKey: 'voucher_create_codes_option2',
    value: 'multiple'
  }
];

export const smartcardsEventsFilterChips = {
  action: 'smartcards_events_tableView_label_action',
  result: 'smartcards_events_tableView_label_result',
  startDate: 'bookings_tableView_label_startDate',
  endDate: 'bookings_tableView_label_endDate',
  smartcardCardId: 'fuelCards_tableView_label_number',
  userLogin: 'common_user_login',
  vehicleRegistrationNumber: 'groups_tableView_label_plateNumber'
};

export const smartcardsFilterChips = {
  userLogin: 'backUsers_tableView_label_email'
};

export const bookingFilterChips = {
  memberFirstName: 'backUsers_tableView_label_firstName',
  memberLastName: 'backUsers_tableView_label_lastName',
  memberLogin: 'backUsers_tableView_label_email',
  status: 'backUsers_tableView_label_status',
  vehicleBrand: 'vehiclesFiltersForm_brand',
  vehicleModel: 'vehiclesFiltersForm_model',
  vehicleRegistrationNumber: 'vehiclesFiltersForm_plateNumber',
  vehicleUsageAtBookingCreation: 'vehiclesFiltersForm_usage',
  delayed: 'booking_detail_delayed',
  endDateMax: 'booking_end_date_before',
  endDateMin: 'booking_end_date_after',
  startDateMax: 'booking_start_date_before',
  startDateMin: 'booking_start_date_after',
  failed: 'bookings_failed',
  integrationFailed: 'bookings_integration_failed',
  rrsUpdateFailed: 'bookings_rrs_failed',
  bookingId: 'feedbacks_tableView_label_bookingId',
  type: 'common_type',
  voucherCode: 'common_searchFor_voucherCode',
  voucherGroupName: 'common_searchFor_VoucherGroupName',
  voucherGroupIds: 'common_searchFor_voucherGroupId'
};

export const vehiclePlanningFilterChips = {
  fuels: 'vehicleForm_fuelType',
  usages: 'vehiclesFiltersForm_usage',
  sites: 'autocomplete_company_sites_title',
  registrationNumber: 'vehiclesFiltersForm_plateNumber'
};

export const companiesFilterChips = {
  name: 'booking_detail_company'
};

export const backUsersFilterChips = {
  firstName: 'backUsers_filterChip_firstName',
  lastName: 'backUsers_filterChip_lastName',
  email: 'backUsers_filterChip_email',
  role: 'backUsers_filterChip_role'
};

export const membersFilterChips = {
  memberName: 'booking_detail_member',
  email: 'backUsers_filterChip_email',
  status: 'members_detail_validationStatus',
  subscriptionOrigins: 'member_subscription_origin',
  expediteReview: 'members_label_type_expediteReview',
  technician: 'members_detail_technician'
};

export const fleetFilterChips = {
  brand: 'vehiclesFiltersForm_brand',
  model: 'vehiclesFiltersForm_model',
  version: 'vehiclesFiltersForm_version',
  usage: 'vehiclesFiltersForm_usage',
  category: 'vehiclesFiltersForm_category',
  systemType: 'vehiclesFiltersForm_systemType',
  vehicleStatus: 'editStatusForm_status',
  cleanliness: 'vehicle_detail_cleanlinessStatus',
  plateNumber: 'feedbacks_tableView_label_plateNumber',
  color: 'vehicle_detail_color',
  type: 'vehicle_detail_type',
  lowFuelLevel: 'dashboard.fuelCharge.Level.label',
  vin: 'vehiclesFiltersForm_VIN',
  telematicDeviceId: 'vehiclesFiltersForm_deviceId',
  maximumBoardVoltage: 'vehiclesFiltersForm_maxVoltage',
  minimumBoardVoltage: 'vehiclesFiltersForm_minVoltage',
  includeUninstalledDevice: 'vehicle_list_include_uninstalled_devices'
};

export const voucherGroupsChips = {
  name: 'common_name',
  voucherType: 'common_type',
  fromCreationDate: 'common_start_date',
  toCreationDate: 'common_end_date'
};

export const invoicesFilterChips = {
  reference: 'bookings_tableView_label_invoice_number',
  status: 'invoices_tableView_label_status',
  startDate: 'invoices_tableView_label_issueDateFrom',
  endDate: 'invoices_tableView_label_issueDateTo'
};

export const invoicesFilterChipsV2 = {
  ...invoicesFilterChips,
  bookingId: 'common_booking_id'
};

export const vouchersChips = {
  revoked: 'voucher_revoked_label',
  creationDate: 'common_creation_date'
};

export const paybackListChips = {
  bookingId: 'common_booking_id',
  creationDatefrom: 'common_creation_date_after',
  creationDateUntil: 'common_creation_date_before'
};

export const bankoutListChips = {
  billingEntityName: 'billing_entity_name_label',
  creationDateLowerLimit: 'common_creation_date_after',
  creationDateUpperLimit: 'common_creation_date_before',
  bankoutStatus: 'bankout_status_label',
  bookingId: 'common_booking_id'
};

export const vehicleDetailChips = {
  from: 'common_start_date',
  to: 'common_end_date'
};

export const feedbackFilterChips = {
  bookingId: 'feedbacks_tableView_label_bookingId',
  memberEmail: 'common_user_login',
  memberName: 'feedbacks_detail_memberName',
  plateNumber: 'feedbacks_detail_plateNumber',
  withCleanlinessProblems: 'feedbacks_label_cleanliness_problem',
  withDamagesDeclared: 'feedbacks_label_damage_declared',
  cleanliness: 'feedbacks_label_statuses',
  status: 'feedbacks_detail_feedback_status'
};

export const vehicleAccessories = [
  {
    labelKey: 'vehicle_detail_accessories_AIR_CONDITIONING',
    value: 'AIR_CONDITIONING'
  },
  {
    labelKey: 'vehicle_detail_accessories_BLUETOOTH',
    value: 'BLUETOOTH'
  },
  {
    labelKey: 'vehicle_detail_accessories_FOLDING_SEATS',
    value: 'FOLDING_SEATS'
  },
  {
    labelKey: 'vehicle_detail_accessories_GPS',
    value: 'GPS'
  },
  {
    labelKey: 'vehicle_detail_accessories_LARGE_TRUCK',
    value: 'LARGE_TRUCK'
  },
  {
    labelKey: 'vehicle_detail_accessories_RADIO_CD',
    value: 'RADIO_CD'
  },
  {
    labelKey: 'vehicle_detail_accessories_BABY_SEAT',
    value: 'BABY_SEAT'
  }
];

export const vehicleTypes = [
  {
    labelKey: 'vehicleForm_type_passenger',
    value: 'PASSENGER'
  },
  {
    labelKey: 'vehicleForm_type_commercial',
    value: 'COMMERCIAL'
  },
  {
    labelKey: 'vehicleForm_type_motorScooter',
    value: 'MOTOR_SCOOTER'
  }
];

export const paymentProviders = [
  {
    labelKey: 'companyForm_payment_provider_worldpay',
    value: PAYMENT_PROVIDERS.WORLDPAY
  },
  {
    labelKey: 'companyForm_payment_provider_stripe',
    value: PAYMENT_PROVIDERS.STRIPE
  }
];

export const tripTyoe = [
  {
    labelKey: 'search_vehicle_oneWay',
    value: ONE_WAY_TRIP
  },
  {
    labelKey: 'search_vehicle_return',
    value: ROUND_TRIP
  }
];

export const vehicleTransmissionTypes = [
  {
    labelKey: 'vehicle_transmissionType_AUTOMATIC',
    value: 'AUTOMATIC'
  },
  {
    labelKey: 'vehicle_transmissionType_MANUAL',
    value: 'MANUAL'
  }
];

export const vehicleFuelTypes = [
  {
    labelKey: 'vehiclesFiltersForm_fuelTypes_ELECTRIC',
    value: VEHICLE_FUEL_TYPE_ELECTRIC
  },
  {
    labelKey: 'vehiclesFiltersForm_fuelTypes_PETROL',
    value: VEHICLE_FUEL_TYPE_PETROL
  },
  {
    labelKey: 'vehiclesFiltersForm_fuelTypes_DIESEL',
    value: VEHICLE_FUEL_TYPE_DIESEL
  },
  {
    labelKey: 'vehiclesFiltersForm_fuelTypes_HYBRID',
    value: VEHICLE_FUEL_TYPE_HYBRID
  },
  {
    labelKey: 'vehiclesFiltersForm_fuelTypes_LPG',
    value: VEHICLE_FUEL_TYPE_LPG
  }
];

export const vehicleNumberOfDoors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const externalInvoiceOptions = [
  {
    labelKey: 'common_no',
    value: EXTERNAL_INVOICE.NO
  },
  {
    label: EXTERNAL_INVOICE.RRS,
    value: EXTERNAL_INVOICE.RRS
  },
  {
    label: EXTERNAL_INVOICE.DMS,
    value: EXTERNAL_INVOICE.DMS
  },
  {
    labelKey: 'external.invoice.italian',
    value: EXTERNAL_INVOICE.ITALIAN
  }
];

export const vehicleOwners = [
  {
    labelKey: 'vehicleForm_vehicleOwner_default',
    value: 'default'
  },
  {
    labelKey: 'vehicleForm_vehicleOwner_currentCompany',
    value: 'currentCompany'
  }
];

export const invoicingCountryOptions = [
  {
    labelKey: 'country_fra',
    value: PAYBACK_COUNTRIES.FRANCE
  },
  {
    labelKey: 'country_che',
    value: PAYBACK_COUNTRIES.SWISS
  },
  {
    labelKey: 'area_eu_union',
    value: PAYBACK_COUNTRIES.UE
  },
  {
    labelKey: 'common_other',
    value: PAYBACK_COUNTRIES.OTHER
  }
];

export const vehicleKeyOptions = [
  {
    labelKey: 'device-with-keys',
    value: 'true'
  },
  {
    labelKey: 'device-keyless',
    value: 'false'
  }
];
export const accessOptions = [
  {
    labelKey: 'common_public',
    value: COMPANY_ACCESS_PUBLIC
  },
  {
    labelKey: 'common_private',
    value: COMPANY_ACCESS_PRIVATE
  }
];

export const bookingPaymentStatus = {
  [PAYMENT_STATUS_PAID]: 'payment_status_PAID',
  [PAYMENT_STATUS_UNPAID]: 'payment_status_UNPAID',
  [BOOKING_STATUS_PARTIALY_PAID]: 'booking_status_partialy_paid'
};

export const bankoutFrequencyOptions = [
  {
    labelKey: 'common_daily',
    value: FREQUENCY_DAILY
  },
  {
    labelKey: 'common_weekly',
    value: FREQUENCY_WEEKLY
  },
  {
    labelKey: 'common_monthly',
    value: FREQUENCY_MONTHLY,
    infoKey: 'bank_transfer_frequency_monthly_info'
  }
];

export const scheduledExportDateFormatOptions = [
  {
    value: 'DD-MM-YYYY',
    label: 'DD-MM-YYYY'
  },
  {
    value: 'DDMMYYYY',
    label: 'DDMMYYYY'
  },
  {
    value: 'YYYY-MM-DD',
    label: 'YYYY-MM-DD'
  },
  {
    value: 'YYYYMMDD',
    label: 'YYYYMMDD'
  }
];

export const scheduledExportTypeOptions = [
  {
    labelKey: 'common_booking',
    value: SCHEDULED_EXPORT.TYPE.BOOKING
  },
  {
    labelKey: 'user_type_member',
    value: SCHEDULED_EXPORT.TYPE.MEMBER_PROFILE
  }
];

export const scheduledExportDestinationOptions = [
  {
    label: 'sFTP',
    value: SCHEDULED_EXPORT.DESTINATION.SFTP
  },
  {
    label: 'S3 (AWS)',
    value: SCHEDULED_EXPORT.DESTINATION.S3
  }
];

export const scheduledExportSecurityOptions = [
  {
    labelKey: 'common_password',
    value: SCHEDULED_EXPORT.SECURITY.PASSWORD
  },
  {
    labelKey: 'common_file',
    value: SCHEDULED_EXPORT.SECURITY.FILE
  }
];

export const scheduledExportFrequencyOptions = [
  {
    labelKey: 'common_daily',
    value: FREQUENCY_DAILY
  },
  {
    labelKey: 'common_weekly',
    value: FREQUENCY_WEEKLY
  },
  {
    labelKey: 'common_monthly',
    value: FREQUENCY_MONTHLY
  }
];

const RED = 'status-red';
const GREEN = 'status-green';
export const ORANGE = 'status-orange';

export const transactionStatus = {
  [STATUS_REFUSED]: { key: 'common_refused', class: RED },
  [STATUS_ERROR]: { key: 'common_error', class: RED },
  [STATUS_CAPTURED]: { key: 'common_captured', class: GREEN },
  [STATUS_SETTLED]: { key: 'common_settled', class: GREEN },
  [STATUS_SENT_FOR_REFUND]: { key: 'transaction_status_sent_for_refund', class: GREEN },
  [STATUS_REFUNDED]: { key: 'common_refunded', class: GREEN },
  [STATUS_REVOKED]: { key: 'common_revoked', class: ORANGE },
  [STATUS_CANCELLED]: { key: 'common_canceled', class: ORANGE },
  [STATUS_AUTHORISED]: { key: 'common_authorised', class: ORANGE },
  [STATUS_EXPIRED]: { key: 'common_expired', class: ORANGE },
  [TRANSACTION_CAPTURED_FAILED]: { key: 'transaction_status_capture_failed', class: ORANGE },
  [TRANSACTION_CHARGEBACK_REVERSED]: { key: 'transaction_status_chargeback_reversed', class: ORANGE },
  [TRANSACTION_CHARGED_BACK]: { key: 'transaction_status_charged_back', class: ORANGE },
  [TRANSACTION_INFORMATION_REQUESTED]: { key: 'transaction_status_information_requested', class: ORANGE },
  [TRANSACTION_INFORMATION_SUPPLIED]: { key: 'transaction_status_information_supplied', class: ORANGE },
  [TRANSACTION_REFUND_FAILED]: { key: 'transaction_status_refund_failed', class: ORANGE },
  [TRANSACTION_REVOKE_FAILED]: { key: 'transaction_status_revoke_failed', class: ORANGE },
  [TRANSACTION_REVOKE_REQUESTED]: { key: 'transaction_status_revoke_requested', class: ORANGE },
  [TRANSACTION_CAPTURE_REQUESTED]: { key: 'transaction_status_capture_requested', class: ORANGE },
  [TRANSACTION_REFUND_REQUESTED]: { key: 'transaction_status_refund_requested', class: ORANGE }
};

export const transactionType = {
  [TRANSACTION_TYPE_BILLING]: 'transaction_type_billing',
  [TRANSACTION_TYPE_DEPOSIT]: 'transaction_type_deposit',
  [TRANSACTION_TYPE_REFUND]: 'transaction_type_refund'
};

export const vehicleUsageTypes = [
  { key: VEHICLE_USAGE_TYPE_NO_USAGE, label: 'vehicle_usage_type_no_usage' },
  { key: VEHICLE_USAGE_TYPE_PLANNED, label: 'vehicle_usage_type_b2b_planned_usage' },
  { key: VEHICLE_USAGE_TYPE_IMMEDIATE, label: 'vehicle_usage_type_b2b_immediate_usage' },
  { key: VEHICLE_USAGE_TYPE_PUBLIC_SERVICE, label: 'vehicle_usage_type_public_service' },
  { key: VEHICLE_USAGE_TYPE_RV, label: 'vehicle_usage_type_rv' },
  { key: VEHICLE_USAGE_TYPE_TEST_DRIVE, label: 'vehicle_usage_type_test_drive' },
  { key: VEHICLE_USAGE_TYPE_DELIVERY, label: 'vehicle_usage_type_delivery' }
];

export const vehicleUsage = {
  [VEHICLE_USAGE_TYPE_NO_USAGE]: 'vehicle_usage_type_no_usage',
  [VEHICLE_USAGE_TYPE_PLANNED]: 'vehicle_usage_type_b2b_planned_usage',
  [VEHICLE_USAGE_TYPE_IMMEDIATE]: 'vehicle_usage_type_b2b_immediate_usage',
  [VEHICLE_USAGE_TYPE_PUBLIC_SERVICE]: 'vehicle_usage_type_public_service',
  [VEHICLE_USAGE_TYPE_RV]: 'vehicle_usage_type_rv',
  [VEHICLE_USAGE_TYPE_TEST_DRIVE]: 'vehicle_usage_type_test_drive',
  [VEHICLE_USAGE_TYPE_DELIVERY]: 'vehicle_usage_type_delivery'
};

export const vehicleDamageTypes = {
  [VEHICLE_DAMAGE_TYPE_START]: 'common_start',
  [VEHICLE_DAMAGE_TYPE_END]: 'common_end'
};

export const vehicleTaskStatuses = {
  [VEHICLE_TASK_STATUS_WAITING_FOR_FIELD_AGENT]: 'task_status_waiting_for_agent',
  [VEHICLE_TASK_STATUS_IN_PROGRESS]: 'task_status_in_progress',
  [VEHICLE_TASK_STATUS_DONE]: 'task_status_done'
};

export const vehicleTaskTypes = {
  [VEHICLE_TASK_TYPE_MAINTENANCE_SERVICE]: 'task_type_maintenance_service',
  [VEHICLE_TASK_TYPE_MOVE_VEHICLE]: 'task_type_move_vehicle',
  [VEHICLE_TASK_TYPE_REPAIR]: 'task_type_repair',
  [VEHICLE_TASK_TYPE_CLEANING]: 'task_type_cleaning',
  [VEHICLE_TASK_TYPE_CHARGING]: 'task_type_charging'
};

export const vehicleTasksTypeOptions = [
  { value: VEHICLE_TASK_TYPE_MAINTENANCE_SERVICE, labelKey: 'task_type_maintenance_service' },
  { value: VEHICLE_TASK_TYPE_MOVE_VEHICLE, labelKey: 'task_type_move_vehicle' },
  { value: VEHICLE_TASK_TYPE_REPAIR, labelKey: 'task_type_repair' },
  { value: VEHICLE_TASK_TYPE_CLEANING, labelKey: 'task_type_cleaning' },
  { value: VEHICLE_TASK_TYPE_CHARGING, labelKey: 'task_type_charging' }
];

export const errorCodes = {
  ['account.user.age.invalid']: 'error.account.user.age.invalid',
  ['invoicing.existing.fiscal.number']: 'common.fiscal.number.duplicate',
  ['company.existing.fiscal.number']: 'common.fiscal.number.duplicate',
  ['booking.end.date.past']: 'booking_detail_extendShorten_error_booking.end.date.past',
  ['account.user.suspended']: 'bookingResult_account.user.suspended',
  ['booking.update.period.expired']: 'bookingResult_booking.update.period.expired',
  ['company.external.id.duplicate']: 'error.company.external.id.duplicate',
  [MEMBER_TYPE_DUPLICATE_NAME_MSG]: 'member_type_error_duplicate_name',
  [PRICING_SLOTS_INVALID_DURATION_MSG]: 'timeSlots_invalid_duration',
  [PRICING_SLOTS_OVERLAP_MSG]: 'timeSlots_overlap',
  [VEHICLE_STATUS_IN_THE_PAST_MSG]: 'validation_date_should_be_in_the_future',
  [VEHICLE_STATUS_OVERLAP_CODE]: 'vehicle_add_status_overlap',
  [VEHICLE_STATUS_15_MINUTES_MSG]: 'vehicle_status_error_15_minutes',
  [FORMATTED_ADDRESS_MISSING]: 'common_error_address_is_empty',
  ['driving.licence.rejection.reason']: 'profile.rejected.driving.licence.invalid'
};

export const partialErrorCodes = {
  generic: {
    primaryPhoneInvalid: {
      partialErrorMsg: 'phoneNumber:',
      translationKey: 'validation_french_phone_number_invalid'
    }
  },
  phoneNumbers: {
    primaryPhoneInvalid: {
      partialErrorMsg: 'phoneNumber:',
      translationKey: 'primary_number_invalid'
    },
    secondaryPhoneInvalid: {
      partialErrorMsg: 'secondaryPhoneNumber:',
      translationKey: 'secondary_number_invalid'
    }
  },
  vehicleInsurance: {
    duplicateContractNumber: {
      partialErrorMsg: 'An insurance contract already exists with contract number',
      translationKey: 'insurance.contract.number.duplicate'
    }
  },
  uploadMember: {
    unsupportedFileType: {
      partialErrorMsg: 'Unsupported content type',
      translationKey: 'member.upload.unsupported.file.type'
    }
  },
  backUsers: {
    editHigherRole: {
      partialErrorMsg: 'You are not allowed to create or edit',
      translationKey: 'error_role_create_edit_forbidden'
    },
    phoneNumberInvalid: {
      partialErrorMsg: 'phoneNumber:',
      translationKey: 'validation_french_phone_number_invalid'
    }
  },
  members: {
    phoneNumberInvalid: {
      partialErrorMsg: 'phoneNumber:',
      translationKey: 'validation_french_phone_number_invalid'
    }
  },
  files: {
    unsupportedFileType: {
      partialErrorMsg: 'Unsupported content type',
      translationKey: 'file.type.upload.error'
    }
  }
};

export const vehicleStatusesLocales = {
  [VEHICLE_STATUSES.REPARATION]: 'vehicle_statusType_REPARATION',
  [VEHICLE_STATUSES.MAINTENANCE]: 'vehicle_statusType_MAINTENANCE',
  [VEHICLE_STATUSES.INTERVENTION]: 'vehicle_statusType_INTERVENTION',
  [VEHICLE_STATUSES.INACTIVE]: 'vehicle_statusType_INACTIVE',
  [VEHICLE_STATUSES.CLEANING]: 'vehicle_statusType_CLEANING',
  [VEHICLE_STATUSES.READY]: 'vehicle_statusType_READY',
  [VEHICLE_STATUSES.RENTED_IN_RRS]: 'vehicle_statusType_RENTED_IN_RRS'
};

export const bookingEventStatuses = {
  [BOOKING_EVENT_STATUSES.SUCCESS]: 'common_success',
  [BOOKING_EVENT_STATUSES.BUSINESS_ERROR]: 'common_business_error',
  [BOOKING_EVENT_STATUSES.TECHNICAL_ERROR]: 'common_technical_error'
};

export const bookingEventTypes = {
  [BOOKING_EVENT_TYPES.CREATE]: 'booking_event_type_create',
  [BOOKING_EVENT_TYPES.CANCEL]: 'booking_event_type_cancel',
  [BOOKING_EVENT_TYPES.DAMAGE_REPORT]: 'booking_event_type_damage_report',
  [BOOKING_EVENT_TYPES.EXTEND_END_DATE]: 'booking_event_type_extend_end_date',
  [BOOKING_EVENT_TYPES.FINISH]: 'booking_event_type_finish',
  [BOOKING_EVENT_TYPES.REASSIGN_VEHICLE]: 'booking_event_type_reassign_vehicle',
  [BOOKING_EVENT_TYPES.SCORE]: 'booking_event_type_score',
  [BOOKING_EVENT_TYPES.START]: 'booking_event_type_start',
  [BOOKING_EVENT_TYPES.UPDATE]: 'booking_event_type_update',
  [BOOKING_EVENT_TYPES.PRE_BOOKING_CONFIRMATION]: 'booking_event_type_prebooking_confirmed'
};

export const bankoutStatusKeys = {
  [BANKOUT_STATUS_EXECUTED]: 'common_status_EXECUTED',
  [BANKOUT_STATUS_PENDING]: 'common_status_PENDING',
  [BANKOUT_STATUS_PROCESSING]: 'common_status_PROCESSING',
  [BANKOUT_STATUS_SUCCESS]: 'common_status_SUCCESS',
  [BANKOUT_STATUS_ERROR]: 'common_status_ERROR'
};

export const billingEntityTypeKeys = {
  [BILLING_ENTITY_TYPE_COMPANY]: 'common_company',
  [BILLING_ENTITY_TYPE_SUPER_COMPANY]: 'common_super_company',
  [BILLING_ENTITY_TYPE_SUPER_COMPANY_ALT]: 'common_super_company'
};
