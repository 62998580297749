import React, { Component } from 'react';
import autoBind from 'react-autobind';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import DomainIcon from 'material-ui/svg-icons/social/domain';
import TooltipIcon from 'material-ui/svg-icons/action/help';
import LocalTaxiIcon from 'material-ui/svg-icons/maps/local-taxi';
import RrsFailedIcon from 'material-ui/svg-icons/alert/warning';
import AirlineSeatReclineExtraIcon from 'material-ui/svg-icons/notification/airline-seat-recline-extra';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, getShortId, namedCompose } from '../../utils/utils';
import { cssColors, iconStyles } from '../../constants/style-constants';
import { FormattedMessage } from 'react-intl';
import _capitalize from 'lodash/capitalize';
import routes, { companyRules } from '../../constants/routes-constants';
import ReactSVG from 'react-svg';
import { isGranted } from '../../actions/user-actions';
import { connect } from 'react-redux';
import { headerContractMemberValuesSelector, localeSelector } from '../../selectors/all-selectors';
import Tooltip from '../Tooltip/Tooltip';
import _isNumber from 'lodash/isNumber';
import { vehicleUsage } from '../../constants/options-constants';
import _map from 'lodash/map';

class BookingInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { firstName, lastName, companyId, memberId, ownerFirstName, ownerLastName, ownerBookingId } = nextProps;
    this.firstName = firstName ? _capitalize(firstName) : '';
    this.lastName = lastName ? _capitalize(lastName) : '';
    this.ownerFirstName = ownerFirstName ? _capitalize(ownerFirstName) : '';
    this.ownerLastName = ownerLastName ? _capitalize(ownerLastName) : '';

    this.ownerHref = ownerBookingId ? `#${routes.bookingDetail.path.replace(':bookingId', ownerBookingId)}` : undefined;
    this.companyHref =
      companyId &&
      isGranted({
        disallowed: companyRules.exclude,
        allowed: companyRules.include
      })
        ? `#${routes.companyDetail.path.replace(':companyId', companyId)}`
        : undefined;
    this.userHref = memberId ? `#${routes.memberDetail.path.replace(':memberId', memberId)}` : undefined;
  }

  addUrl(child, href) {
    if (href) return <a href={href}>{child}</a>;
    else return child;
  }

  wrapBrackets(value) {
    return <span> ({value})</span>;
  }

  carSeats() {
    const { vehicleSeats } = this.props;
    if (_isNumber(vehicleSeats)) return this.wrapBrackets(vehicleSeats);
  }

  seatsTooltip() {
    const { vehicleSeats, reservedSeats } = this.props;
    if (vehicleSeats && reservedSeats)
      return (
        <span>
          <FormattedMessage id="booking_detail_reservedSeats" />
          {this.wrapBrackets(<FormattedMessage id="vehicle_total_seats" />)}
        </span>
      );
    else return <FormattedMessage id="booking_detail_reservedSeats" />;
  }

  reservedSeats() {
    const { reservedSeats } = this.props;
    if (_isNumber(reservedSeats))
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={this.seatsTooltip()} placement="bottom">
              <AirlineSeatReclineExtraIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">
            {reservedSeats}
            {this.carSeats()}
          </span>
        </div>
      );
  }

  rrsFailed() {
    const { rrsFailed } = this.props;
    if (rrsFailed)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <RrsFailedIcon style={iconStyles.all} />
          </span>
          <span className="detailView_cell_type_1 error-line">
            <FormattedMessage id="bookings_rrs_failed" />
          </span>
        </div>
      );
  }

  getDmsErrors() {
    const dmsErrors = this.props.dmsInvoiceErrors || [];

    if (dmsErrors.length) {
      return (
        <div>
          {_map(dmsErrors, ({ invoiceId, errorMessage }, n) => (
            <div key={n}>{getShortId(invoiceId) + ': ' + errorMessage}</div>
          ))}
        </div>
      );
    }
  }

  dmsFailed() {
    const dmsErrors = this.getDmsErrors();

    if (dmsErrors) {
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <RrsFailedIcon style={iconStyles.all} />
          </span>
          <span className="detailView_cell_type_1 error-line">
            <FormattedMessage id="booking.error.dms" />
            <span>&nbsp;</span>
            <Tooltip content={dmsErrors} placement="right">
              <TooltipIcon style={{ color: cssColors.backgroundDark }} />
            </Tooltip>
          </span>
        </div>
      );
    }
  }

  italianInvoiceFailed() {
    const { italianInvoiceError } = this.props;

    if (italianInvoiceError) {
      const error = (
        <span>
          {italianInvoiceError.italianInvoiceNumber} ({italianInvoiceError.emissionDate}): {italianInvoiceError.errorDetails}
        </span>
      );

      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <RrsFailedIcon style={iconStyles.all} />
          </span>
          <span className="detailView_cell_type_1 error-line">
            <FormattedMessage id="bookings_italian_invoice_failed" />
            <span>&nbsp;</span>
            <Tooltip content={error} placement="right">
              <TooltipIcon style={{ color: cssColors.backgroundDark }} />
            </Tooltip>
          </span>
        </div>
      );
    }
  }

  integrationFailed() {
    const { integrationFailed, integrationErrors } = this.props;

    if (integrationFailed) {
      const error =
        integrationErrors.length > 0 ? (
          <span>
            {getAppFormattedDateTime(integrationErrors[0].createdOn)} : {integrationErrors[0].message}
          </span>
        ) : (
          <span></span>
        );

      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <RrsFailedIcon style={iconStyles.all} />
          </span>
          <span className="detailView_cell_type_1 error-line">
            <FormattedMessage id="bookings_integration_failed" />
            <span>&nbsp;</span>
            <Tooltip content={error} placement="right" themeClass="tooltip-grey-wide">
              <TooltipIcon style={{ color: cssColors.backgroundDark }} />
            </Tooltip>
          </span>
        </div>
      );
    }
  }

  ownerName() {
    if (this.ownerFirstName || this.ownerLastName) {
      const name = (this.ownerFirstName + ' ' + this.ownerLastName).trim();
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="booking_detail_owner" />} placement="bottom">
              <ReactSVG src="/img/pilot-hat.svg" svgClassName="svg-icon" />
            </Tooltip>
          </span>
          {this.addUrl(<span className="detailView_cell_type_1">{name}</span>, this.ownerHref)}
        </div>
      );
    }
  }

  userName() {
    if (this.firstName || this.lastName) {
      const name = (this.firstName + ' ' + this.lastName).trim();
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="booking_detail_member" />} placement="bottom">
              <AccountCircleIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          {this.addUrl(<span className="detailView_cell_type_1">{name}</span>, this.userHref)}
        </div>
      );
    }
  }

  userType() {
    const { bookingMemberType, memberRules } = this.props;

    if (memberRules.type && bookingMemberType) {
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="common_member_type" />} placement="bottom">
              <ReactSVG src="/img/user-gear.svg" svgClassName="svg-icon" />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">{bookingMemberType}</span>
        </div>
      );
    }
  }

  openToRideSharing() {
    const { openToRideSharing } = this.props;
    if (openToRideSharing)
      return (
        <span className="bookingInfo_headline_icon_container">
          <Tooltip content={<FormattedMessage id="booking_detail_rideSharing" />} placement="bottom">
            <LocalTaxiIcon style={iconStyles.all} />
          </Tooltip>
        </span>
      );
  }

  companyName() {
    const { companyName } = this.props;
    if (companyName)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="booking_detail_company" />} placement="bottom">
              <DomainIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">{this.addUrl(companyName, this.companyHref)}</span>
        </div>
      );
  }

  bookingId() {
    const { bookingId } = this.props;
    if (bookingId)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="booking_detail_identifier" />} placement="bottom">
              <ReactSVG src="/img/hash.svg" svgClassName="svg-icon" />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">{bookingId}</span>
        </div>
      );
  }

  bookingUsage() {
    const { bookingUsage } = this.props;
    if (bookingUsage)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="label_booking_usage" />} placement="bottom">
              <ReactSVG src="/img/booking-usage.svg" svgClassName="svg-icon" />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">
            <FormattedMessage id={vehicleUsage[bookingUsage]} />
          </span>
        </div>
      );
  }

  render() {
    return (
      <div className="bookingInfo">
        <h4 className="bookingInfo_headline">
          <FormattedMessage id="booking_detail_title_information" />
          {this.openToRideSharing()}
        </h4>
        <div className="bookingInfo_body">
          <div className="detailView_table">
            {this.bookingId()}
            {this.userName()}
            {this.userType()}
            {this.ownerName()}
            {this.companyName()}
            {this.reservedSeats()}
            {this.bookingUsage()}
            {this.rrsFailed()}
            {this.dmsFailed()}
            {this.italianInvoiceFailed()}
            {this.integrationFailed()}
          </div>
        </div>
      </div>
    );
  }
}

BookingInfo.defaultProps = {
  bookingMemberType: undefined, // String
  companyName: undefined, // String
  bookingId: undefined, // String
  companyId: undefined, // String
  openToRideSharing: false, // boolean
  firstName: undefined, // String
  lastName: undefined, // String
  memberId: undefined, // String
  reservedSeats: undefined, // Number
  vehicleSeats: undefined, // Number
  ownerFirstName: undefined, // String
  ownerLastName: undefined, // String
  ownerBookingId: undefined, // String
  rrsFailed: undefined, // Boolean
  italianInvoiceError: undefined, // Object
  dmsInvoiceErrors: [], // Array
  integrationErrors: [] // Array
};

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state), // pure exception
      memberRules: headerContractMemberValuesSelector(state)
    };
  }),
  pure
)(BookingInfo);
